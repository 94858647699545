import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function JobPostStayInLoop({
  setFieldValue,
  values,
  touched,
  errors,
  handleBlur,
}) {
  return (
    <div className="job-post-inner-wrapper">
      <div>
        <div className="modal-text-wrapper">
          <h2>Stay in the Loop</h2>
          <p className="medium medium-bold">
            Your phone number lets us keep you updated on job opportunities,{" "}
            <br />
            payments, and important notifications.
          </p>
        </div>
        <div className="form-group">
          <label htmlFor="phone" className="medium">
            Phone Number
          </label>
          <PhoneInput
            country={"us"}
            value={values.phone}
            onChange={(value) => setFieldValue("phone", value)} // Set value using Formik
            onBlur={handleBlur}
            inputProps={{
              name: "phone",
              className: `form-control blue-text ${
                errors.phone ? "is-invalid" : ""
              }`,
            }}
          />
        </div>
        {touched.phone && errors.phone && (
          <div
            style={{
              color: "#DB2719",
              fontSize: "13px",
            }}
          >
            {errors.phone}
          </div>
        )}
      </div>
    </div>
  );
}
