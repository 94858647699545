import React, { useEffect, useRef, useState } from "react";

import { motion, AnimatePresence } from "framer-motion";
import ProgressBar from "react-bootstrap/ProgressBar";

import Modal from "react-bootstrap/Modal";

import logo from "../assets/images/icons/logo.svg";
import { FiArrowRight } from "react-icons/fi";
import { FiArrowLeft } from "react-icons/fi";
import { HiMiniInformationCircle } from "react-icons/hi2";

import JobPostStayInLoop from "./JobPostStayInLoop";
import JobPostConnectTikTokShop from "./JobPostConnectTikTokShop";
import JobPostWhatMakesYou from "./JobPostWhatMakesYou";
import JobPostPickContent from "./JobPostPickContent";
import JobPostUGCRate from "./JobPostUGCRate";
import JobPostShopRates from "./JobPostShopRates";
import JobPostWelcomeToBMC from "./JobPostWelcomeToBMC";
import JobPostShopRatesSamplePreview from "./JobPostShopRatesSamplePreview";
import { useDispatch, useSelector } from "react-redux";
import {
  setScreenLock,
  updateCreatorProfileAsyncThunk,
} from "../redux/pagesSlices/authSlice";
import { setValueIfExist } from "../utils/utils";
import * as Yup from "yup";
import { act } from "react-dom/test-utils";
import { Form, Formik } from "formik";
import JobPostConnectStripeAccountSlide from "./JobPostConnectStripeAccountSlide";

const stepZeroSchema = Yup.object({
  tikTokUserName: Yup.string()
    .required("TikTok Shop tikTokUserName is required")
    .matches(
      /^[a-zA-Z0-9_]+$/,
      "Only letters, numbers, and underscores are allowed"
    ),
});

const stepOneSchema = Yup.object({
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{10,15}$/, "Enter a valid phone number"), // Matches numbers between 10 to 15 digits
});

const stepTwoSchema = Yup.object({
  bio: Yup.string()
    .required("This field is required")
    .min(20, "Your bio must be at least 20 characters")
    .max(300, "Your bio cannot exceed 300 characters"),
});
const stepThreeSchema = Yup.object({
  categories: Yup.array()
    .min(1, "Please select at least one category")
    .required("You must choose at least one category"),
});
const stepFourSchema = Yup.object({
  ugc_price: Yup.number()
    .typeError("UGC Rate must be a number")
    .required("Price must be at least 1")
    .min(1, "Price must be at least 1"),
});
const stepFiveSchema = Yup.object({
  videoPrices: Yup.array().of(
    Yup.object({
      duration: Yup.string().required("duration is required"),
      durationCount: Yup.number().required("duration count is required"),
      count: Yup.number().required("count is required"),
      price: Yup.number().min(0, "Price must be at least 0"),
      // .required("price is required"),
    })
  ),
  livePrices: Yup.array().of(
    Yup.object({
      duration: Yup.string().required("duration is required"),
      durationCount: Yup.number().required("duration count is required"),
      count: Yup.number().required("count is required"),
      price: Yup.number().min(0, "Price must be at least 0"),
      // .required("price is required"),
    })
  ),
});

export default function JobPostModalComponent(props, { modalCloseHandler }) {
  const { profile } = useSelector((s) => s.auth);
  const { connectedAccount, loadings } = useSelector((s) => s.payment);
  const [onboardingExited, setOnboardingExited] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const formikRef = useRef();
  const handleNext = () => {
    formikRef.current.submitForm();
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };
  const handleSeeShow = () => {
    setActiveStep(8);
  };

  const d = useDispatch();
  const handleSubmit = (values) => {
    const sanitizedValues = {
      ...values,
      videoPrices: values.videoPrices.map((item) => ({
        ...item,
        price: item.price === "" ? 0 : item.price,
      })),
      livePrices: values.livePrices.map((item) => ({
        ...item,
        price: item.price === "" ? 0 : item.price,
      })),
    };
    d(setScreenLock(true));
    d(
      updateCreatorProfileAsyncThunk({
        data: setValueIfExist(sanitizedValues),
        callBack: () => setActiveStep(values?.steps === 4 ? 6 : 7),
      })
    );
  };
  const sanitizedVideoPrices = profile?.videoPrices.map(
    ({ _id, ...rest }) => rest
  );
  const sanitizedLivePrices = profile?.livePrices.map(
    ({ _id, ...rest }) => rest
  );
  return (
    <div className="job-post-modal-component">
      <Modal
        {...props}
        size="lg"
        className="job-post-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {activeStep !== 8 && (
              <div className="logo-wrapper">
                <img src={logo} alt="logo" />
              </div>
            )}
            {activeStep === 8 && (
              <button
                className="custom-btn blue medium-bold back-btn"
                onClick={() => setActiveStep(5)}
              >
                <FiArrowLeft /> Back
              </button>
            )}
            {activeStep === 5 && (
              <>
                <div className="dont-worry-about-access-div">
                  <HiMiniInformationCircle />
                  <span className="medium">
                    Don’t worry you’ll still be able to access this in your
                    profile settings
                  </span>
                </div>
              </>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="job-post-steps-wrapper">
            <Formik
              innerRef={formikRef}
              initialValues={{
                tikTokUserName: profile?.tikTokUserName || "",
                phone: profile?.phone || "",
                bio: profile?.bio || "",
                categories: profile?.categories || [],
                ugc_price: profile?.ugc_price || "",
                stripe_account_id: profile?.stripe_account_id || "",
                videoPrices:
                  sanitizedVideoPrices?.length > 0
                    ? sanitizedVideoPrices
                    : [
                        {
                          duration: "monthly",
                          durationCount: 1,
                          count: 5,
                          price: 0,
                        },
                        {
                          duration: "monthly",
                          durationCount: 1,
                          count: 10,
                          price: 0,
                        },
                        {
                          duration: "monthly",
                          durationCount: 1,
                          count: 20,
                          price: 0,
                        },
                        {
                          duration: "monthly",
                          durationCount: 1,
                          count: 30,
                          price: 0,
                        },
                      ],
                livePrices:
                  sanitizedLivePrices?.length > 0
                    ? sanitizedLivePrices
                    : [
                        {
                          duration: "weekly",
                          durationCount: 1,
                          count: 4,
                          price: 0,
                        },
                        {
                          duration: "weekly",
                          durationCount: 1,
                          count: 12,
                          price: 0,
                        },
                        {
                          duration: "weekly",
                          durationCount: 1,
                          count: 20,
                          price: 0,
                        },
                        {
                          duration: "weekly",
                          durationCount: 1,
                          count: 30,
                          price: 0,
                        },
                      ],
              }}
              validationSchema={
                activeStep === 0
                  ? stepZeroSchema
                  : activeStep === 1
                  ? stepOneSchema
                  : activeStep === 2
                  ? stepTwoSchema
                  : activeStep === 3
                  ? stepThreeSchema
                  : activeStep === 4
                  ? stepFourSchema
                  : stepFiveSchema
              }
              onSubmit={(values, actions) => {
                if (activeStep < 5) {
                  setActiveStep(activeStep + 1);
                } else {
                  if (activeStep === 5) {
                    handleSubmit({ ...values, steps: 4 });
                  } else {
                    handleSubmit({ ...values, steps: 5 });
                  }
                }
                actions.setSubmitting(false);
              }}
            >
              {({
                isSubmitting,
                values,
                errors,
                setFieldValue,
                handleBlur,
                touched,
              }) => {
                console.log("errors", errors);
                return (
                  <Form className="form">
                    <motion.div layout className="step-content">
                      <AnimatePresence mode="wait">
                        <motion.div
                          key={activeStep}
                          initial={{ opacity: 0, x: 100 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: -100 }}
                          transition={{ duration: 0.5 }}
                        >
                          {activeStep === 0 && (
                            <>
                              <JobPostConnectTikTokShop errors={errors} />
                            </>
                          )}
                          {activeStep === 1 && (
                            <>
                              <JobPostStayInLoop
                                setFieldValue={setFieldValue}
                                errors={errors}
                                values={values}
                                touched={touched}
                                handleBlur={handleBlur}
                              />
                            </>
                          )}
                          {activeStep === 2 && (
                            <>
                              <JobPostWhatMakesYou
                                errors={errors}
                                values={values}
                              />
                            </>
                          )}
                          {activeStep === 3 && (
                            <>
                              <JobPostPickContent
                                errors={errors}
                                values={values}
                                touched={touched}
                              />
                            </>
                          )}
                          {activeStep === 4 && (
                            <>
                              <JobPostUGCRate errors={errors} />
                            </>
                          )}
                          {activeStep === 5 && (
                            <>
                              <JobPostShopRates
                                errors={errors}
                                values={values}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                handleSeeShow={handleSeeShow}
                              />
                            </>
                          )}
                          {activeStep === 6 && (
                            <>
                              <JobPostConnectStripeAccountSlide
                                setFieldValue={setFieldValue}
                                values={values}
                                setOnboardingExited={setOnboardingExited}
                              />
                            </>
                          )}
                          {activeStep === 7 && (
                            <>
                              <JobPostWelcomeToBMC />
                            </>
                          )}
                          {activeStep === 8 && (
                            <>
                              <JobPostShopRatesSamplePreview
                                handleSeeShow={handleSeeShow}
                              />
                            </>
                          )}
                        </motion.div>
                      </AnimatePresence>
                    </motion.div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Modal.Body>
        {activeStep !== 7 && activeStep !== 8 && (
          <>
            <Modal.Footer>
              <ProgressBar now={((activeStep + 1) / 8) * 100} />
              <button
                className="btn-style"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <FiArrowLeft /> Back
              </button>
              <buttononboardingExited
                className="btn-style"
                onClick={handleNext}
                disabled={
                  activeStep === 7 ||
                  (activeStep === 6 &&
                    Object.entries(connectedAccount).length > 0 &&
                    connectedAccount?.capabilities?.transfers !== "active")
                }
              >
                Next <FiArrowRight />{" "}
              </buttononboardingExited>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </div>
  );
}
