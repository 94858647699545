import React from "react";
import { useSelector } from "react-redux";
import { parseImagePathToURL } from "../utils/globalFunctions";
import jobImg1 from "../assets/images/job-img-1.svg";
import { returnArray } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import { usePresenceListener } from "ably/react";
import onGoingJobStatus from '../assets/images/icons/ongoing-job-status.svg'

export default function DashboardMyMessages() {
  // const unreadMesages =
  const notifications = useSelector((s) => s.chat.rooms);
  console.log("🚀 ~ DashboardMyMessages ~ notifications:", notifications);
  const navigate = useNavigate();
  const isUserOnline = (userId) => {
    return presenceData.some((client) => client.clientId === userId);
  };
  const { presenceData } = usePresenceListener(
    { channelName: "notifications" },
    (update) => {
      console.log(update);
    }
  );

  return (
    <>

      {returnArray(notifications).length ? (
        returnArray(notifications.slice(0, 4)).map((room, index) => {
          const isGroup = room?.type === "group";
          const isSystem = room?.type === "system";
          const handleClick = async () => {
            navigate(`/dashboard/messages?id=${room?.user?.id || room?.user?._id}&jobId=${room?.applicationId?.jobId?._id || room?.applicationId?.jobId?.id}`);
          };
          return (
            <div className="dashboard-my-messages-main-wrapper">
              <div
                className={`message-wrapper ${isUserOnline(room?.user?.user || room?.user?.id || room?.user?._id) ? "active" : ""}  `}
                key={index}
                onClick={handleClick}
              >
                <div className="img-wrapper">
                  <img
                    src={
                      room?.user?.avatar
                        ? parseImagePathToURL(room.user.avatar)
                        : jobImg1
                    }
                    alt="avatar-img"
                  />
                </div>
                <div className="text-wrapper">
                  <strong className="dark medium semi_bold">
                    {isGroup || isSystem ? room?.name : room?.user?.name}
                  </strong>
                  <span>{room.room?.lastMessage}</span>
                  {room.unreadMessagesCount > 0 && (
                    <strong className="notifications-num white">
                      {room.unreadMessagesCount}
                    </strong>
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="no-home-card-data-wrapper text-center">
          <div className="img-wrapper">
            <img src={onGoingJobStatus} alt="onGoingJobStatus" />
          </div>
        </div>
      )}

    </>

  );
}
