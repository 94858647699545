import React, { useEffect, useState } from "react";

import FindJobsPageHeader from "../components/FindJobsPageHeader";
import JobCardComponent from "../components/JobCardComponent";
import JobCardComponentListView from "../components/JobCardComponentListView";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";
import { getAvailableJobsAsyncThunk } from "../redux/pagesSlices/jobSlice";
import { useDispatch, useSelector } from "react-redux";
import SideBarComponent from "../components/SideBarComponent";
import NoJobsComponent from "../components/NoJobsComponent";
import { toggleSidebarMobile } from "../redux/pagesSlices/authSlice";
import noJobsImg from '../assets/images/icons/no-jobs-img.svg'

export default function FindJobsPage({ sidebarShow }) {
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState({
    categories: [],
    promotionType: [],
    preferredGender: ["All"],
    // status : "All"
  });
  console.log("🚀 ~ FindJobsPage ~ selectedFilters:", selectedFilters);
  const [viewMode, setViewMode] = useState("grid");
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("createdAt:desc");
  const [sideBarOpen, setSideBarOpen] = useState(true);

  // Search and filter handlers
  const handleSearchChange = (e) => setSearch(e.target.value);

  const sideBarOpenHandler = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const setSidebarShow = useSelector((s) => s.auth.showSidebarOrNot);

  // Fetch jobs based on filter changes
  useEffect(() => {
    const fetchParams = {
      sortBy,
      ...(search && { jobTitle: search }),
      // ...(promotionType && { promotionType }),
      ...(selectedFilters.promotionType?.length > 0 && {
        types: selectedFilters.promotionType,
      }),
      // ...(selectedFilters.status &&
      //   selectedFilters.status !== "All" && {
      //   status: selectedFilters.status.toLowerCase(),
      // }),
      ...(selectedFilters.preferredGender !== "All" && {
        gender: selectedFilters.preferredGender
          .filter((item) => item !== "All") // Remove "All" from the array
          .map((item) => item.toLowerCase()),
      }),
      ...(selectedFilters.categories?.length > 0 && {
        categories: selectedFilters.categories.filter((item) => item !== "All"),
      }),
      populate: "brandId",
      limit : 9
    };

    const timeout = setTimeout(() => {
      dispatch(getAvailableJobsAsyncThunk(fetchParams));
    }, 500);

    return () => clearTimeout(timeout);
  }, [dispatch, sortBy, search, selectedFilters]);

  // console.log("selectedFilters", selectedFilters);

  return (
    <div className="main-inner-page-wrapper">
      <SideBarComponent
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        sideBarOpen={sideBarOpen}
        sidebarShow={sidebarShow}
        // dateFilter={true}
        clearAllFilters={() =>
          setSelectedFilters({
            categories: [],
            promotionType: [],
            preferredGender: "All",
            // status: "All",
          })
        }
        arrangementArray={[
          // { name: "StatusFilter", type: "single" },
          { name: "PromotionType", type: "multiple" },
          { name: "Categories", type: "multiple" },
          { name: "PreferredGender", type: "multiple" },
        ]}
        sideBarOpenHandler={sideBarOpenHandler}
      />
      <div className={`inner-page-wrapper ${sideBarOpen ? "active" : ""}`}>
        <div className="find-jobs-page">
          <FindJobsPageHeader
            sortBy={sortBy}
            setSortBy={setSortBy}
            search={search}
            handleSearchChange={handleSearchChange}
            jobsViewHandler={setViewMode}
            jobsView={viewMode}
            exLargeContainer={true}
          />

          <div className="jobs-section">
            <div className="container ex-large">
              <div
                className={`row overflow-x-auto ${
                  viewMode === "grid" ? "" : "list-row"
                }`}
              >
                <ProviderErrorLoadingPagination
                  reducer="job"
                  action="getAvailableJobsAsyncThunk"
                  asyncThunk={getAvailableJobsAsyncThunk}
                  dataKey="availableJobs"
                  Component={
                    viewMode === "grid"
                      ? // ? <div className="md-col-4 mb-5">
                        //   <JobCardComponent/>
                        // </div>
                        JobCardComponent
                      : JobCardComponentListView
                  }
                  emptyMessage="No jobs found!"
                  emptyComponent={<NoJobsComponent image={noJobsImg} title="No jobs found!" btnText={"Browse Jobs"} route={"/dashboard/find-jobs"} description="Browse available jobs and start applying."/>}
                  pagination
                  itemKey="job"
                  componentProps={{
                    jobTitle: "Job Title",
                    brandName: "Brand Name",
                    jobPostedTime: "",
                    className: "col-md-4 mb-5",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="overLay"
        className={`${setSidebarShow ? "active" : ""}`}
        onClick={() => dispatch(toggleSidebarMobile(false))}
      ></div>
    </div>
  );
}
