import React, { useEffect, useState } from "react";
import AddPaymentModal from "../modals/AddPaymentModal";
import paymenttabCreditcard from "../../assets/images/icons/payementtabCreditcard.svg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import LoaderComponent from "../PaginateLoaderComponent";
import {
  accountOnboardingAsyncThunk,
  connectAccountAsyncThunk,
  getConnectedAccountAsyncThunk,
} from "../../redux/pagesSlices/paymentSlice.js";
import useStripeConnect from "../../hooks/useStripeConnect.js";
import { FaArrowLeftLong } from "react-icons/fa6";

import stripeTextIcon from "../../assets/images/icons/stripe-text-icon.svg";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { Spinner } from "react-bootstrap";

export default function PaymentTab({ tabContentMobileCloseHandler }) {
  const { connectedAccount, loadings } = useSelector((s) => s.payment);
  const { creator } = useSelector((s) => s.auth);
  const stripeConnectInstance = useStripeConnect(connectedAccount?.id);
  const [onboardingExited, setOnboardingExited] = useState(false);
  const dispatch = useDispatch();
  const accountNumber = connectedAccount?.external_accounts?.data[0]?.id;
  const accountHolderName =
    connectedAccount?.external_accounts?.data[0]?.account_holder_name;
  console.log("accountNumber", connectedAccount);
  const handleConnect = () => {
    dispatch(connectAccountAsyncThunk({}));
  };

  useEffect(() => {
    if (creator?.stripe_account_id) {
      dispatch(
        getConnectedAccountAsyncThunk({ id: creator?.stripe_account_id })
      );
    }
  }, [onboardingExited]);

  return (
    <>
      <div className="payment-tab-wrapper">
        <div className="portal-settings-tab-header payment-header-section d-flex align-items-center gap-3">
          <FaArrowLeftLong
            className="d-block d-md-none"
            onClick={tabContentMobileCloseHandler}
            style={{
              width: 22,
              height: 17,
              color: "#1A202C",
              borderRadius: 0,
              minWidth: "auto",
            }}
          />

          <strong className="dark large">Connected Account</strong>
        </div>
        <div className="portal-header-bottom-bar"></div>
        {creator?.stripe_account_id && connectedAccount ? (
          <div className="container">
            {connectedAccount?.capabilities?.transfers == "active" && (
              <div className="payment-detail-main-wrapper">
                <div className="number-detail">
                  <div className="bank-icon">
                    <img src={stripeTextIcon} alt="stripeTextIcon" />
                  </div>
                  <div className="text-wrapper">
                    <div className="text">
                      <strong className="semi_bold dark">
                        {accountHolderName || creator?.userName}
                      </strong>
                      <p className="medium">{accountNumber}</p>
                    </div>
                    <div className="btn-wrapper">
                      <a href="#" className="btn-style transparent">
                        Disconnect bank
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col">
                {stripeConnectInstance &&
                  connectedAccount?.capabilities?.transfers !== "active" && (
                    <ConnectComponentsProvider
                      connectInstance={stripeConnectInstance}
                    >
                      <ConnectAccountOnboarding
                        onExit={() => setOnboardingExited(true)}
                      />
                    </ConnectComponentsProvider>
                  )}
              </div>
            </div>
          </div>
        ) : (
          <>
            {loadings.getConnectedAccountAsyncThunk ? (
              <LoaderComponent />
            ) : (
              <>
                <div className="payment-bottom-content">
                  <div className="img-text-section">
                    <img src={paymenttabCreditcard} alt="credit card" />
                    <strong className="dark semi_bold medium">
                      No pay out method added yet
                    </strong>
                    <span>
                      Link your credit card or bank account for easy payment.
                    </span>
                  </div>
                  <button
                    disabled={loadings?.connectAccountAsyncThunk}
                    onClick={handleConnect}
                  >
                    {loadings?.connectAccountAsyncThunk ? (
                      <Spinner size="sm" />
                    ) : (
                      "Add account"
                    )}
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
