import { Link, useNavigate } from "react-router-dom";
import avatarIcon from "../../assets/images/icons/avatar-icon.svg";
import { useState } from "react";
import { parseImagePathToURL } from "../../utils/globalFunctions";
import { FaArrowLeftLong } from "react-icons/fa6";

const ChatHeader = ({
  selectedRoom,
  users,
  user,
  presenceData,
  currentUser,
  handleActiveChatClose,
  activeChatState
}) => {
  const isUserOnline = (userId) => {
    return presenceData.some((client) => client.clientId === userId);
  };
  const [show, setShow] = useState(false);
  const router = useNavigate();
  console.log("🚀 ~ ChatHeader ~ currentUser:", currentUser);
  console.log("🚀 ~ creatorInfo ~ users:", users);
  return (
    <div className="top-bar-wrapper">
      <div className="name-wrapper">
        <div className="img-wrapper d-flex gap-2 align-items-center">
          <FaArrowLeftLong className={`d-lg-none ${activeChatState ? 'd-block d-md-block' : 'd-none d-md-none'}`} style={{width : 22 , height : 17 , color:'#596780' , borderRadius  : 0 , minWidth : 'auto'}} onClick={handleActiveChatClose} />
          <img src={currentUser?.avatar
            ? parseImagePathToURL(currentUser.avatar)
            : avatarIcon} alt="avatarIcon" />
        </div>
        <div className="text-wrapper" onClick={() => {
          if (user?.role === "brand") {
            router(
              `/dashboard/application/${currentUser?.applicationId?._id || currentUser?.applicationId?.id}/creator/${currentUser?.id || currentUser?._id || currentUser?.user}`
            );
          } else {
            router(`/dashboard/job/${currentUser?.applicationId?.jobId?._id || currentUser?.applicationId?.jobId?.id || currentUser?.applicationId?.jobId}`);
          }
        }}>
          <strong className="dark large semi_bold">{currentUser?.name || selectedRoom?.name}</strong>
          <span className="status">{isUserOnline(
            currentUser?.user || currentUser?.id || currentUser?._id
          ) ? "Online" : "Offline"}</span>
        </div>
      </div>
      <Link to="#" className="btn-style transparent blue-border">
        View project details
      </Link>
    </div>
  );
};

export default ChatHeader;
