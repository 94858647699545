import React from "react";

import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import withDrawlProposalICon from "../assets/images/icons/remove-icon.svg";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withdrawApplicationAsyncThunk } from "../redux/pagesSlices/applicationSlice";
import { getId } from "../utils/utils";

export default function WithDrawlProposalModal({
  application = {},
  callBack,
  withDrawlModalCloseHandler,
  activeWithdrawlModal,
}) {
  console.log("WithDrawlProposalModal:application", application);
  const deleteId = getId(application);
  const loading = useSelector(
    (s) => s.application.loadings?.withdrawApplicationAsyncThunk
  );
  const dispatch = useDispatch();

  const handleWithdrawl = () => {
    dispatch(
      withdrawApplicationAsyncThunk({
        id: deleteId,
        data: {
          withdraw: !application?.withdraw,
        },
        callBack,
      })
    );
  };
  return (
    <div className="withdrawl-proposal-modal-wrapper custom-modal-wrapper">
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={activeWithdrawlModal}
        className="withdrawl-proposal-modal custom-modal"
        onHide={withDrawlModalCloseHandler}
      >
        <Modal.Header withDrawlModalCloseHandler>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-wrappr">
            <div className="img-wrapper">
              <div className="img">
                <img src={withDrawlProposalICon} alt="withDrawlProposalICon" />
              </div>
            </div>
            <div className="content-wrapper">
              <h3>Withdraw Proposal</h3>
              <p className="medium medium-bold">
                Are you sure you want to withdraw your proposal? This action
                cannot be undone and will remove your offer from consideration.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link
            to="#"
            className="btn-style transparent light large"
            onClick={withDrawlModalCloseHandler}
          >
            Keep Proposal
          </Link>
          <Button
            className="btn-style large"
            disabled={loading}
            onClick={handleWithdrawl}
          >
            {loading ? <Spinner /> : "Withdraw Proposal"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
