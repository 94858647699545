import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRoomAsyncThunk,
  fetchMessagesAsyncThunk,
  fetchRoomsAsyncThunk,
  fetchUsersWithRoomAsyncThunk,
  readUnreadMessagesAsyncThunk,
  setMessages,
  setRooms,
} from "../redux/pagesSlices/chatSlice";
import { ChannelProvider, useChannel, usePresenceListener } from "ably/react";
import ChatSection from "../components/Chat/chatSection";
import ChatHeader from "../components/Chat/chatHeader";
import Emptyscreen from "../components/Chat/Emptyscreen";
import ChatSidebar from "../components/Chat/chatSidebar";

export default function MessagesPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRoom, setSelectedRoom] = useState(null);
  const paramID = searchParams.get("id");
  const jobId = searchParams.get("jobId");
  const [activeChatState, setActiveChatState] = useState(false);
  const users = useSelector((state) => state.chat.users);
  const rooms = useSelector((state) => state.chat.rooms);
  const [currentUser, setCurrentUser] = useState(null);
  const roomMessages = useSelector((state) => state.chat.messages);
  const messages = roomMessages?.results || [];
  const [isLoading] = useState(false);
  const [roomLoading, setRoomLoading] = useState(false);
  const [page, setPage] = useState(roomMessages?.page || 1);
  const totalPages = roomMessages?.totalPages || 0;
  const [isInfiniteLoading, setIsInfiniteLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const handleActiveChatOpen = () => {
    setActiveChatState(true);
  };
  const handleActiveChatClose = () => {
    setActiveChatState(false);
  };

  useEffect(() => {
    dispatch(fetchUsersWithRoomAsyncThunk({}));
  }, [dispatch]);

  const getMessages = async () => {
    dispatch(fetchMessagesAsyncThunk({ selectedRoom, page }));
  };

  const { presenceData } = usePresenceListener(
    { channelName: "notifications" },
    (update) => {
      console.log(update);
    }
  );

  const handleDeleteRoom = async (roomId) => {
    dispatch(
      deleteRoomAsyncThunk({
        roomId,
        callBack: async () => {
          await getRoomsOfUser();
          if (selectedRoom?._id === roomId || selectedRoom?.id === roomId) {
            setSelectedRoom(null);
            dispatch(setMessages([]));
            setCurrentUser(null);
          }
        },
      })
    );
  };
  const joinRoom = async (selectedUser, room, unreadMessagesCount) => {
    setPage(1); // Reset page when switching rooms
    setCurrentUser(selectedUser);
    if (room) {
      setSelectedRoom(room);
      dispatch(
        readUnreadMessagesAsyncThunk({
          roomId: room?.id || room?._id,
          callBack: () => {
            dispatch(
              setRooms(
                rooms.map((r) => {
                  if ((room?.id || room?._id) === (r?.id || r?._id)) {
                    return { ...r, unreadMessagesCount: 0 };
                  }
                  return r;
                })
              )
            );
          },
        })
      );
    } else {
      setPage(1); // Reset page when switching rooms
      setSelectedRoom({
        name: `Chat between ${user?.id || user?._id} and ${
          selectedUser?.user || selectedUser?.id || selectedUser?._id
        }`,
      });
    }
    // else if (room){
    //   setSelectedRoom(room);
    //   setCurrentUser({user:room.sender_id})
    // }
  };

  const getRoomsOfUser = async () => {
    dispatch(fetchRoomsAsyncThunk({}));
  };
  useEffect(() => {
    setRoomLoading(true); // Start loading
    getRoomsOfUser().finally(() => {
      setRoomLoading(false); // Stop loading once data is fetched
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { channel: notification } = useChannel("notifications");

  useEffect(() => {
    if (currentUser) {
      setIsInfiniteLoading(true);
      getMessages().finally(() => {
        setIsInfiniteLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, currentUser]);

  useEffect(() => {
    if (paramID&& jobId && users?.length > 0) {
      const selectedUser = rooms.find(
        (room) =>
          (room?.user?.id || room?.user?._id) === paramID &&
          (room?.applicationId?.jobId?._id ||
            room?.applicationId?.jobId?.id ||
            room?.applicationId?.jobId) === jobId
      );
      joinRoom(selectedUser?.user, selectedUser);
      //   router.replace(`/dashboard/chat`);
      setSearchParams({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramID, users, setSearchParams, jobId]);

  return (
    <div className="inner-page-wrapper active m-0">
      <div className="messages-page">
        <div className="container">
          <div className="row">
            <div className={`col-md-12 col-lg-4 d-lg-block ${activeChatState ? 'd-none d-md-none'  : 'd-block d-md-block'}`}>
              <ChatSidebar
                currentUser={currentUser}
                handleDeleteRoom={handleDeleteRoom}
                rooms={rooms}
                joinRoom={joinRoom}
                user={user}
                getRoomsOfUser={getRoomsOfUser}
                users={users}
                selectedRoom={selectedRoom}
                presenceData={presenceData}
                roomLoading={roomLoading}
                handleActiveChatOpen={handleActiveChatOpen}
                activeChatState={activeChatState}
              />
            </div>
            <div className={`col-lg-8 col-md-12 d-lg-block  ${activeChatState ? 'd-block d-md-block' : 'd-none d-md-none'}`}>
              <div className="message-detail-main-wrapper">
                {currentUser ? (
                  <ChannelProvider channelName={selectedRoom?.name}>
                    <ChatHeader
                      setCurrentUser={setCurrentUser}
                      selectedRoom={selectedRoom}
                      presenceData={presenceData}
                      currentUser={currentUser}
                      users={users}
                      user={user}
                      handleActiveChatClose={handleActiveChatClose}
                      activeChatState={activeChatState}
                    />
                    <ChatSection
                      channelName={selectedRoom?.name}
                      currentUser={currentUser}
                      user={user}
                      messages={messages}
                      setMessages={setMessages}
                      getRoomsOfUser={getRoomsOfUser}
                      notification={notification}
                      presenceData={presenceData}
                      isLoading={isLoading}
                      setPage={setPage}
                      page={page}
                      totalPages={totalPages}
                      isInfiniteLoading={isInfiniteLoading}
                    />
                  </ChannelProvider>
                ) : (
                  <div className="chat_section" style={{ height: "100%" }}>
                    <div className="container-fluid" style={{ height: "100%" }}>
                      <Emptyscreen />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
