import React, { useEffect } from "react";
import { FieldArray, useFormik } from "formik";
import * as Yup from "yup";
import { categoriesLabels } from "../utils/onBoardingCategorieslLabels";
export default function JobPostPickContent({ errors, touched, values }) {
  const contentArray = [
    // "⚡ Electronics",
    // "👗 Fashion",
    // "🍎 Beauty & Health",
    // "🏠 Home & Living",
    // "🎮 Toys & Games",
    // "🚗 Automotive",
    // "📷 Books & Media",
    // "🛒 Groceries & Food",
    // "🐶 Pet Supplies",
    // "💼 Office Supplies",
    // "🛠️ Tools & Hardware",
    // "💎 Jewellery & Watches",
    // "🏃🏻 Sports & Outdoors",
    // "👶🏻 Baby & Kids",
    // "🎨 Arts & Collectibles",
    "Electronics",
    "Fashion",
    "Beauty & Health",
    "Home & Living",
    "Toys & Games",
    "Automotive",
    "Books & Media",
    "Groceries & Food",
    "Pet Supplies",
    "Office Supplies",
    "Tools & Hardware",
    "Jewellery & Watches",
    "Sports & Outdoors",
    "Baby & Kids",
    "Arts & Collectibles",
  ];

  return (
    <div className="job-post-inner-wrapper">
      <div>
        <div className="modal-text-wrapper">
          <h2>Pick Your Content Style</h2>
          <p className="medium medium-bold">
            Choose the categories that fit your content best. This helps brands{" "}
            <br />
            find creators like you who vibe with their audience.
          </p>
        </div>
        <div className="pick-content-list-wrapper">
          <FieldArray
            name="categories"
            render={(arrayHelpers) => (
              <ul className="pick-content-list">
                {contentArray.map((category, index) => (
                  <li key={index}>
                    <label className="custom-radio">
                      <span>{categoriesLabels[category]}</span>
                      <input
                        type="checkbox"
                        name="categories"
                        value={category}
                        checked={values.categories.includes(category)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            arrayHelpers.push(category); // Add to array
                          } else {
                            const idx = values.categories.indexOf(category);
                            arrayHelpers.remove(idx); // Remove from array
                          }
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                ))}
              </ul>
            )}
          />
          {touched.categories && errors.categories && (
            <div
              style={{
                color: "#DB2719",
                fontSize: "13px",
              }}
            >
              {errors.categories}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
