import React, { useState } from "react";
import leftCheveIcon from "../assets/images/icons/left-chev-icon.svg";
import { useSelector } from "react-redux";

export default function SideBarComponent({
  selectedFilters = {
    categories: [],
    promotionType: [],
    preferredGender: [],
    status: [],
  },
  setSelectedFilters = () => {},
  clearAllFilters = () => {},
  sideBarOpen,
  sideBarOpenHandler,
  sidebarShow,
  dateFilter,
  arrangementArray = [
    { name: "Categories", type: "multiple" },
    { name: "PromotionType", type: "multiple" },
    { name: "PreferredGender", type: "multiple" },
    { name: "StatusFilter", type: "single" },
  ],
}) {
  const [activeMenu, setActiveMenu] = useState([]);
  const setSidebarShow = useSelector((s) => s.auth.showSidebarOrNot);
  const dateFilterCheck = dateFilter;
  console.log("date filter check", dateFilterCheck);
  

  const toggleMenu = (menu) => {
    console.log("🚀 ~ toggleMenu ~ menu:", menu);
    setActiveMenu((prevMenu) => {
      if (prevMenu.includes(menu)) {
        return prevMenu.filter((item) => item !== menu);
      }
      return [...prevMenu, menu];
    });
  };

  const handleFilterChange = (category, value, type = "multiple") => {
    setSelectedFilters((prevFilters) => {
      if (type === "single") {
        return {
          ...prevFilters,
          [category]: value,
        };
      }
      const currentValues = prevFilters[category];
      const isSelected = currentValues.includes(value);
      const isAllSelected = value === "All";
      return {
        ...prevFilters,
        [category]: isAllSelected
          ? ["All"]
          : isSelected
          ? currentValues.filter((item) => item !== value)
          : [...currentValues, value].filter((item) => item !== "All"),
      };
    });
  };

  const components = {
    Categories: Categories,
    PromotionType: PromotionType,
    PreferredGender: PreferredGender,
    StatusFilter: StatusFilter,
  };

  return (
    <div
      className={`sidebar-main-wrapper ${sideBarOpen ? "active" : ""} ${
        setSidebarShow ? "show" : "hide"
      }`}
    >
      <div className="sidebar-top-bar">
        <div className="filter-text-wrapper" onClick={sideBarOpenHandler}>
          <strong className="medium">Filter</strong>
          <img
            src={leftCheveIcon}
            alt="leftCheveIcon"
            className="left-chev-icon"
          />
        </div>
        {!sideBarOpen && (
          <button
            onClick={clearAllFilters}
            className="blue custom-btn semi_bold"
          >
            Clear All
          </button>
        )}
      </div>
      <div className="sidebar-main-listings-wrapper">
        <div className="listing-wrapper">
          <ul className="listings listing-category-titles">
            {arrangementArray.map((category, index) => {
              const Component = components[category.name];
              return (
                <Component
                  key={index}
                  toggleMenu={toggleMenu}
                  activeMenu={activeMenu}
                  selectedFilters={selectedFilters}
                  handleFilterChange={(key, value) =>
                    handleFilterChange(key, value, category.type)
                  }
                  dateFilter={dateFilterCheck}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

const Categories = ({
  toggleMenu,
  activeMenu,
  selectedFilters,
  handleFilterChange,
  dateFilter
}) => {
  console.log("dateFilterCheck", dateFilter);
  
  const categoriesArray = [
    "All",
    "Beauty & Care",
    "Business & Finance",
    "Fashion & Style",
    "Food & Drinks",
    "Gaming & Tech",
    "Health & Wellness",
    "Home & Garden",
    "Kids & Parenting",
    "Lifestyle",
    "Outdoors & Nature",
    "Pets",
    "Sport & Fitness",
    "Travel",
  ];
  return (
    <li
      onClick={() => toggleMenu("categories")}
      className={activeMenu.includes("categories") ? "active" : ""}
    >
      Categories
      <ul className="sub-menu-list" onClick={(e) => e.stopPropagation()}>
        {categoriesArray.map((category, index) => (
          <li key={index}>
            <div className="filter-category">
              <div className="custom-checkbox-wrapper category-checkbox">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={selectedFilters.categories.includes(category)}
                    onChange={() => handleFilterChange("categories", category)}
                  />
                  <span className="checkmark"></span>
                  <span className="sub-category-name">{category}</span>
                </label>
              </div>
            </div>
          </li>
        ))}
        {dateFilter && (
          <li>
            <div className="filter-category">
              <div className="custom-checkbox-wrapper category-checkbox">
                <label className="checkbox-label">
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                  <div className="range-wrapper">
                    <div className="range">
                      <label htmlFor="from">From:</label>
                      <input
                        type="date"
                        placeholder="1 Jan 2024"
                        className="input"
                      />
                    </div>
                    <span>-</span>
                    <div className="range">
                      <label htmlFor="from">to:</label>
                      <input
                        type="date"
                        placeholder="1 Jan 2024"
                        className="input"
                      />
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </li>
        )}
      </ul>
    </li>
  );
};

const PromotionType = ({
  toggleMenu,
  activeMenu,
  selectedFilters,
  handleFilterChange,
}) => {
  const promotionTypeArray = ["video", "live"];
  return (
    <li
      onClick={() => toggleMenu("promotionType")}
      className={activeMenu.includes("promotionType") ? "active" : ""}
    >
      Proposal type
      <ul className="sub-menu-list" onClick={(e) => e.stopPropagation()}>
        {promotionTypeArray.map((promotionType, index) => (
          <li key={index}>
            <div className="filter-category">
              <div className="custom-checkbox-wrapper category-checkbox">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={selectedFilters.promotionType.includes(
                      promotionType
                    )}
                    onChange={() =>
                      handleFilterChange("promotionType", promotionType)
                    }
                  />
                  <span className="checkmark"></span>
                  <span className="sub-category-name">{promotionType}</span>
                </label>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </li>
  );
};

const PreferredGender = ({
  toggleMenu,
  activeMenu,
  selectedFilters,
  handleFilterChange,
}) => {
  const preferredGenderArray = ["All", "Male", "Female", "Other"];
  return (
    <li
      onClick={() => toggleMenu("preferredGender")}
      className={activeMenu.includes("preferredGender") ? "active" : ""}
    >
      Preferred Gender
      <ul className="sub-menu-list" onClick={(e) => e.stopPropagation()}>
        {preferredGenderArray.map((preferredGender, index) => (
          <li key={index}>
            <div className="filter-category">
              <div className="custom-checkbox-wrapper category-checkbox">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={selectedFilters.preferredGender.includes(
                      preferredGender
                    )}
                    onChange={() =>
                      handleFilterChange("preferredGender", preferredGender)
                    }
                  />
                  <span className="checkmark"></span>
                  <span className="sub-category-name">{preferredGender}</span>
                </label>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </li>
  );
};

const StatusFilter = ({
  toggleMenu,
  activeMenu,
  selectedFilters,
  handleFilterChange,
}) => {
  const array = [
    { label: "Applied", value: "applied" },
    { label: "Hired", value: "approved" },
    { label: "Rejected", value: "pass" },
  ];
  return (
    <li
      onClick={() => toggleMenu("preferredGender")}
      className={activeMenu.includes("preferredGender") ? "active" : ""}
    >
      Status Filter
      <ul className="sub-menu-list" onClick={(e) => e.stopPropagation()}>
        {array.map((item, index) => (
          <li key={index}>
            <div className="filter-category">
              <div className="custom-checkbox-wrapper category-checkbox">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={selectedFilters.status === item.value}
                    onChange={() => handleFilterChange("status", item.value)}
                  />
                  <span className="checkmark"></span>
                  <span className="sub-category-name">{item.label}</span>
                </label>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </li>
  );
};
