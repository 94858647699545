import React, { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import * as Ably from "ably";
import { AblyProvider, ChannelProvider } from "ably/react";
import DashboardHeader from "../components/DashboardeHeader";
import { useDispatch, useSelector } from "react-redux";
import { useTour } from "@reactour/tour";
import { updateProfile } from "../redux/pagesSlices/authSlice";


export default function Dashboard() {
  const [sidebarShow, setSidebarShow] = useState(false);
  const auth = useSelector((s) => s.auth);
  const {user} = useSelector((s)=>s.auth)
  
  const client = useMemo(
    () =>
      auth?.user?.id || auth?.user?._id
        ? new Ably.Realtime({
            key: process.env.REACT_APP_ABLY_API_KEY,
            clientId: auth?.user?.id || auth?.user?._id,
          })
        : null,
    [auth?.user?._id, auth?.user?.id]

  );

  const {setIsOpen} = useTour()

  const dispatch = useDispatch();


    const handleTour = async() => {
    const isLargeScreen = window.innerWidth > 992;

    if (isLargeScreen && !user?.isUserTourOpen) {
        setIsOpen(true)
        dispatch(updateProfile({
          data : {
            isUserTourOpen : true
          }
        }))
    }
    }

    useEffect(()=>{
        handleTour()

    },[setIsOpen,user?.isUserTourOpen])


  const sideBarOpenMobileHandler = () => {
    setSidebarShow(true);
  };
  const sideBarCloseMobileHandler = () => {
    setSidebarShow(false);
  };
  return (
    <div className="dashboard-page">
      <AblyProvider client={client}>
        <ChannelProvider channelName="notifications">
          <DashboardHeader  />
          <Outlet />
        </ChannelProvider>
      </AblyProvider>
    </div>
  );
}
