import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

import linkIcon from "../assets/images/icons/link-fill-icon.svg";
import barIcon from "../assets/images/bar-icon.svg";
import { FaInfoCircle } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";
import QRCode from "qrcode";
import { toast } from "react-toastify";

export default function LinkTrackingUrlModal({
  editModalCloseHandler,
  editModalState,
  proposalDetails,
}) {
  console.log(
    "🚀 ~ LinkTrackingUrlModal ~ proposalDetails:",
    proposalDetails?.jobId?.tapLink
  );

  const [qrCodeUrl, setQrCodeUrl] = useState("");

  // Generate the QR Code URL based on proposalDetails?.jobId?.tapLink
  useEffect(() => {
    const generateQRCode = async () => {
      const link =
        proposalDetails?.jobId?.tapLink || "https://default-link.com"; // Fallback URL
      try {
        const qrUrl = await QRCode.toDataURL(link);
        setQrCodeUrl(qrUrl); // Save the QR code URL in state
      } catch (err) {
        console.error("Error generating QR code", err);
      }
    };
    generateQRCode();
  }, [proposalDetails?.jobId?.tapLink]);

  const handleCopyToClipboard = () => {
    const link = proposalDetails?.jobId?.tapLink || "https://default-link.com";

    navigator.clipboard.writeText(link)
      .then(() => {
        toast.success("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy the link: ", err);
      });
  };



  return (
    <div className="link-tracking-url-mdoal-wrapper">
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={editModalState}
        className="link-tracking-modal custom-modal"
        onHide={editModalCloseHandler}
      >
        <Modal.Header editModalCloseHandler>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-wrappr">
            <div className="img-wrapper">
              <div className="img">
                <img src={linkIcon} alt="linkIcon" />
              </div>
            </div>
            <div className="content-wrapper">
              <h3>Link Tracking URL</h3>
              <p className="medium medium-bold">
                To connect your tracking URL, please scan the QR code using your
                mobile device. This will automatically sync the tracking
                information with your account.
              </p>
            </div>
            <div className="scan-bar-icon-wrapper">
              {qrCodeUrl && (
                <img src={qrCodeUrl} alt="QR Code" className="qr-code-icon" />
              )}
            </div>
            <div className="warning-wrapper">
              <FaInfoCircle />
              <span>
                Once scanned, you’ll be redirected to your tracking URL and this
                step will be marked complete.
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button to="#" className="btn-style large">
            Continue
          </button>
          <div className="or-wrapper">
            <strong className="medium">OR enter the link manually</strong>
          </div>
          <div className="copy-link-wrapper">
            <strong className="medium">
              {proposalDetails?.jobId?.tapLink?.length > 50
                ? proposalDetails?.jobId?.tapLink?.slice(0, 40) + " ..."
                : proposalDetails?.jobId?.tapLink || "https://default-link.com"}
            </strong>
            <button onClick={handleCopyToClipboard}>
              <MdContentCopy />
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
