import React from "react";

export default function LoginAsBrandBottomBar() {
  return (
    <div className="login-as-brand-bottom-bar">
      <p className="medium">
        Log in as a brand? Click{" "}
        <a
          href={process.env.REACT_APP_BRAND_BASE_URL + "sign-in"}
          className="pink bold underline"
        >
          here
        </a>
      </p>
    </div>
  );
}
