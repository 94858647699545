import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../detectError";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// const toast = { error: () => { }, success: () => { } };
import { toast } from "react-toastify";
import { getId } from "../../utils/utils";

// Start Application Slices
///////////////////////////////////////////////////

export const getApplicationsAsyncThunk = createAsyncThunk(
  "application/getApplicationsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getApplications(params);
    return response?.data;
  })
);

export const getMyApplicationsAsyncThunk = createAsyncThunk(
  "application/getMyApplicationsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getMyApplications(params);
    return response?.data;
  })
);
export const getMyApplicationsCountAsyncThunk = createAsyncThunk(
  "application/getMyApplicationsCountAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getMyApplicationsCount(params);
    return response?.data;
  })
);

export const getBrandApplicationsAsyncThunk = createAsyncThunk(
  "application/getBrandApplicationsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getBrandApplications(params);
    return response?.data;
  })
);

export const getHiredCreatorsAsyncThunk = createAsyncThunk(
  "application/getHiredCreatorsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getHiredCreators(params);
    return response?.data;
  })
);

export const getJobApplicationsAsyncThunk = createAsyncThunk(
  "application/getJobApplicationsAsyncThunk",
  catchAsync(async ({ id, params }, _) => {
    const response = await ApiRequests.getJobApplications({ id, params });
    return response?.data;
  })
);

export const getApplicationAsyncThunk = createAsyncThunk(
  "application/getApplicationAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getApplication(id);
    return response?.data;
  })
);

export const getApplicationDetailAsyncThunk = createAsyncThunk(
  "application/getApplicationDetailAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getApplicationDetail(id);
    return response?.data;
  })
);

export const createApplicationAsyncThunk = createAsyncThunk(
  "application/createApplicationAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    // const state = getState();
    const response = await ApiRequests.createApplication(data);
    if (response.status == 204) {
      toast.success("Application Create Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `name:${state1.order}`;
    dispatch(
      getApplicationsAsyncThunk({
        ...params,
        populate: "application_id",
        role: "Application",
      })
    );
    // dispatch(getApplicationsByIdsAsyncThunk({ ...state.applications?.paramsForThunk?.getApplicationsByIdsAsyncThunk}))
    return response?.data;
  })
);

export const updateApplicationAsyncThunk = createAsyncThunk(
  "application/updateApplicationAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.updateApplication({ id, data });
    if (response.status == 204) {
      toast.success("Application Updated Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `name:${state1.order}`;
    dispatch(
      getApplicationsAsyncThunk({
        ...params,
        populate: "application_id",
        role: "Application",
      })
    );
    // dispatch(getApplicationsByIdsAsyncThunk({ populate: "image,application_id", ...state.applications?.paramsForThunk?.getApplicationsByIdsAsyncThunk, page: 1 }))
    return response?.data;
  })
);

export const requestApplicationSampleAsyncThunk = createAsyncThunk(
  "application/requestApplicationSampleAsyncThunk",
  catchAsync(async({id,data,callBack},{dispatch,getState})=>{
    const state = getState();
    console.log("id received at backend",id);
    
    const response  =  await ApiRequests.applicationSampleRequestStatus(id,data)
    if (response.status == 204) {
      toast.success("Application Updated Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `name:${state1.order}`;
    dispatch(
      getApplicationsAsyncThunk({
        ...params,
        populate: "application_id",
        role: "Application",
      })
    );
    return response?.data
  })
)

export const receiveApplicationSampleAsyncThunk = createAsyncThunk(
  "application/receiveApplicationSampleAsyncThunk",
  catchAsync(async({id,data,callBack},{dispatch,getState})=>{
    const state = getState();
    const response  =  await ApiRequests.applicationSampleReceiveStatus(id,data)
    if (response.status == 200) {
      toast.success("Sample Confirmation Sent Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `name:${state1.order}`;
    dispatch(
      getApplicationsAsyncThunk({
        ...params,
        populate: "application_id",
        role: "Application",
      })
    );
    return response?.data
  })
)

export const receiveApplicationPaymentAsyncThunk = createAsyncThunk(
  "application/receiveApplicationPaymentAsyncThunk",
  catchAsync(async({id,callBack},{dispatch,getState})=>{
    const state = getState();
    console.log("Data received at thunk",id);
    
    const response  =  await ApiRequests.applicationPaymentReceiveStatus(id)
    console.log("response",response);
    
    if (response.status == 200) {
      toast.success("Payment Confirmation Sent!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `name:${state1.order}`;
    dispatch(
      getApplicationsAsyncThunk({
        ...params,
        populate: "application_id",
        role: "Application",
      })
    );
    return response?.data
  })
)

export const reviewApplicationAsyncThunk = createAsyncThunk(
  "application/reviewApplicationAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.reviewApplication({ id, data });
    if (data.status === "pass") {
      toast.success(
        "The creator has been passed and will no longer appear in your Applied list."
      );
    } else if (data.status === "applied") {
      toast.success(
        "The creator has been unpassed and is now available for further review."
      );
    } else if (data.status === "approved") {
      toast.success(
        "The creator has been approved! Please read the agreement and click the Agree button to proceed.."
      );
    } else {
      toast.success("Application Reviewed Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const toggleApplicationFavoriteAsyncThunk = createAsyncThunk(
  "application/toggleApplicationFavoriteAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.toggleApplicationFavorite({ id, data });
    if (response.status == 204) {
      toast.success("Application Updated Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const toggleApplicationViewedAsyncThunk = createAsyncThunk(
  "application/toggleApplicationViewedAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.toggleApplicationViewed({ id, data });
    if (response.status == 204) {
      toast.success("Application Updated Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const creatorAgreementAsyncThunk = createAsyncThunk(
  "application/creatorAgreementAsyncThunk",
  catchAsync(async ({ id, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.agreeCreatorContractApplication({ id });
    if (response.status == 204) {
      toast.success("Application Updated Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const brandAgreementAsyncThunk = createAsyncThunk(
  "application/brandAgreementAsyncThunk",
  catchAsync(async ({ id, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.agreeBrandContractApplication({ id });
    if (response.status == 204) {
      toast.success("Application Updated Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const deleteApplicationAsyncThunk = createAsyncThunk(
  "application/deleteApplicationAsyncThunk",
  catchAsync(async ({ id, callBack }, { dispatch, getState }) => {
    // const response = await ApiRequests.getAssets(filterparams);
    const response = await ApiRequests.deleteApplication(id);
    if (response.status == 204) {
      toast.success("Application Deleted Successfully!");
      if (callBack) callBack();
      dispatch(
        getMyApplicationsAsyncThunk({
          populate: "jobId",
          sortBy: "createdAt:desc",
        })
      );

      // let params = {};
      // let state = getState().listings;
      // if (state.search) params.name = state.search;
      // if (state.order) params.sortBy = `name:${state.order}`;
      // dispatch(
      //   getMyApplicationsAsyncThunk({ ...params, populate: "application_id", role: "Application" })
      // );
    } else {
      toast.error(response.error);
    }
    return id;
  })
);
export const withdrawApplicationAsyncThunk = createAsyncThunk(
  "application/withdrawApplicationAsyncThunk",
  catchAsync(
    async (
      { id, data = { withdraw: true }, callBack = () => {} },
      { dispatch, getState }
    ) => {
      const response = await ApiRequests.withdrawApplication({ id, data });
      if (response.status === 200) {
        toast.success("Application withdrawn successfully!");
        if (callBack) callBack();
      } else {
        toast.error(response.error);
      }
      return response.data;
    }
  )
);

///////////////////////////////////////////////////

const initialState = {
  //news states
  applications: {
    page: 1,
    results: [],
    totalPages: 1,
    totalResults: 1,
    limit: 10,
  },
  brandApplicants: {
    page: 1,
    results: [],
    totalPages: 1,
    totalResults: 1,
    limit: 10,
  },
  hiredCreators: {
    page: 1,
    results: [],
    totalPages: 1,
    totalResults: 1,
    limit: 10,
  },
  myApplications: {
    page: 1,
    results: [],
    totalPages: 1,
    totalResults: 1,
    limit: 10,
  },
  jobApplications: {
    page: 1,
    results: [],
    totalPages: 1,
    totalResults: 1,
    limit: 10,
  },
  applicationsCount: {
    page: 1,
    results: [],
    totalPages: 1,
    totalResults: 1,
    limit: 10,
  },
  inviteApplication: {
    page: 1,
    results: [],
    totalPages: 1,
    totalResults: 1,
    limit: 10,
  },
  applicationExport: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  applicationRole: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  applicationsList: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  application: null,
  jobApplicationUpdate: null,
  applicationDetail: null,
  assets: null,
  asset: null,
  listings: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  // manager states
  applicationsCount: {},
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
    setJobApplicationUpdateEmpty(state, action) {
      state.jobApplicationUpdate = null;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(getApplicationsAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.applications = {
            page: 1,
            results: [],
            totalPages: 1,
          };
        }
      })
      .addCase(getApplicationsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.applications = {
            ...action.payload,
            applications: state?.applications?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.applications = action.payload;
        }
      })
      .addCase(getMyApplicationsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.myApplications = {
            ...action.payload,
            myApplications: state?.myApplications?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.myApplications = action.payload;
        }
      })
      .addCase(getBrandApplicationsAsyncThunk.fulfilled, (state, action) => {
        state.brandApplicants = action.payload;
      })
      .addCase(getMyApplicationsCountAsyncThunk.fulfilled, (state, action) => {
        state.applicationsCount = action.payload;
      })
      .addCase(getHiredCreatorsAsyncThunk.fulfilled, (state, action) => {
        state.hiredCreators = action.payload;
      })
      .addCase(getJobApplicationsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.jobApplications = {
            ...action.payload,
            results: state?.jobApplications?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.jobApplications = action.payload;
        }
      })
      .addCase(getApplicationAsyncThunk.fulfilled, (state, action) => {
        state.application = action.payload;
      })
      .addCase(getApplicationDetailAsyncThunk.fulfilled, (state, action) => {
        state.applicationDetail = action.payload;
      })
      .addCase(toggleApplicationFavoriteAsyncThunk.pending, (state, action) => {
        if (state.application) {
          state.application = {
            ...(state.application && typeof state.application === "object"
              ? state.application
              : {}),
            favorite: !state.application.favorite,
          };
        } else {
          state.application = {
            favorite: true,
          };
        }
      })
      .addCase(creatorAgreementAsyncThunk.fulfilled, (state, action) => {
        state.jobApplicationUpdate = action.payload;
      })
      .addCase(withdrawApplicationAsyncThunk.fulfilled, (state, action) => {
        state.application = action.payload;
        if (state.myApplications.results.length > 0) {
          state.myApplications.results = state.myApplications.results.map(
            (e) => {
              if (getId(e) === getId(action?.payload)) {
                return action.payload;
              }
              return e;
            }
          );
        }
      })
      .addCase(brandAgreementAsyncThunk.fulfilled, (state, action) => {
        state.jobApplicationUpdate = action.payload;
        state.application = {
          ...(state.application && typeof state.application === "object"
            ? state.application
            : {}),
          ...action.payload,
        };
      })
      .addCase(deleteApplicationAsyncThunk.fulfilled, (state, action) => {
        state.applications = {
          ...state.applications,
          totalResults: state.applications?.totalResults - 1,
          results: state.applications?.results.filter(
            (e) => e.id != action.payload
          ),
        };
        state.applicationsCount = {
          ...state.applicationsCount,
          totalResults: state.applicationsCount?.totalResults - 1,
          results: state.applicationsCount?.results.filter(
            (e) => e.id != action.payload
          ),
        };
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getApplicationsAsyncThunk,
            getApplicationAsyncThunk,
            deleteApplicationAsyncThunk,
            withdrawApplicationAsyncThunk,
            createApplicationAsyncThunk,
            reviewApplicationAsyncThunk,
            toggleApplicationFavoriteAsyncThunk,
            toggleApplicationViewedAsyncThunk,
            updateApplicationAsyncThunk,
            getJobApplicationsAsyncThunk,
            getBrandApplicationsAsyncThunk,
            getHiredCreatorsAsyncThunk,
            getMyApplicationsAsyncThunk,
            getApplicationDetailAsyncThunk,
            creatorAgreementAsyncThunk,
            brandAgreementAsyncThunk,
            getMyApplicationsCountAsyncThunk,
            receiveApplicationPaymentAsyncThunk,
            receiveApplicationSampleAsyncThunk,
            requestApplicationSampleAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default applicationSlice.reducer;
export const {
  setLoading,
  setSearchValue,
  setCategoryValue,
  setOrderValue,
  setJobApplicationUpdateEmpty,
} = applicationSlice.actions;
