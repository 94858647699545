import React, { useEffect, useState } from "react";

import JobPostModalComponent from "../components/JobPostModalComponent";
import { FaCalendarAlt } from "react-icons/fa";
import { CircularProgressbar } from 'react-circular-progressbar';
import { HiArrowRight } from "react-icons/hi";

import BannerImg from '../assets/images/dashboard-home-banner-img.svg'
import pieChartFill from '../assets/images/icons/pie-chart-fill.svg'
import myGvmICon from '../assets/images/icons/my-gvm-icon.svg'
import gvmPerVideo from '../assets/images/icons/gvm-per-video.svg'
import noJobImg from '../assets/images/no-job-img.svg'
import arrowInBoxIcon from '../assets/images/icons/arrow-in-box.svg'
import noCreditCard from '../assets/images/no-credit-card.svg'
import jobImg1 from '../assets/images/job-img-1.svg'
import videofile from '../assets/images/icons/video-file-wrapper.svg'
import liveIcon from '../assets/images/icons/live-icon.svg'
import DashboardServicePrice from "../components/DashboardServicePrice";
import DashboardMyMessages from "../components/DashboardMyMessages";
import DashboardTrendsChartComponent from "../components/DashboardTrendsChartComponent";
import TrendLineChart from "../components/TrendLineChart";
import CreatorDashboardStatesComponent from "../components/CreatorDashboardStatesComponent";
import jobImage from '../assets/images/job-img.svg'
import jobImage1 from '../assets/images/job-img-1.svg'
import jobImage2 from '../assets/images/job-img-2.svg'
import brandImage from '../assets/images/brand-img.svg'
import brandImage1 from '../assets/images/brand-img-1.svg'
import brandImage2 from '../assets/images/brand-img-2.svg'

import { HiInformationCircle } from "react-icons/hi2";
import { IoMdTrendingDown } from "react-icons/io";
import { IoMdTrendingUp } from "react-icons/io";
import SmallStatesChart from "../components/SmallStatesChart";
import RecommendedJobCardComponent from "../components/RecommendedJobCardComponent ";
import { useDispatch, useSelector } from "react-redux";
import { showUserName } from "../utils/format";
import { returnArray } from "../utils/utils";
import { getHiredJobsAsyncThunk } from "../redux/pagesSlices/jobSlice";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { Link } from "react-router-dom";
import noOngoingJobs from "../assets/images/no-ongoing-jobs.svg"
import noMessages from "../assets/images/no-chat.svg"

export default function DashboardHome() {

    const [modalState, setModalState] = useState(false);
    const [trendActiveState, setTrendActiveState] = useState('gmv');

    const jobs = useSelector(s => s.job.hiredJobs);
    const auth = useSelector(s => s.auth);
    const user = auth.user;
    const creator = auth.creator;

    const d = useDispatch();
    useEffect(() => {
        d(getHiredJobsAsyncThunk({ populate: 'brandId', limit: 5 }))
    }
        , [])

    const trendActiveStateHandler = (tab) => {
        setTrendActiveState(tab)
    }

    const modalOpenHandler = () => {
        setModalState(true)
    }
    const modalCloseHandler = () => {
        setModalState(false)
    }
    return (
        <div className="inner-page-wrapper active m-0">
            <div className="dashboard-home-page">
                <div className="banner-section">
                    <div className="container ex-large">
                        <div className="row">
                            <div className="col-md-7 align-self-center order-2 order-md-1">
                                <div className="banner-content">
                                    <h2 className="white">Welcome, {showUserName(user)}!</h2>
                                    <p className="large medium-bold">Ready to kick off your journey? Discover job listings, set your rates, and showcase your unique skills to attract brands.</p>
                                </div>
                            </div>
                            <div className="col-md-5 align-self-center order-1 order-md-2 px-0 mb-2 mb-md-0">
                                <div className="banner-img">
                                    <img src={BannerImg} alt="BannerImg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-boarding-jobs-section">
                <div className="container ex-large">
                        <div className="row">
                            <div className="col-md-8 mb-4">
                                <div className="job-info-wrapper">
                                    <div className="title-wrapper">
                                        <strong className="large dark">Ongoing Job Status</strong>
                                        <div className="info-icon-wrapper">
                                            <Link to="/dashboard/my-jobs">
                                                <img src={arrowInBoxIcon} alt="arrowInBoxIcon" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="inner-content-wrapper">
                                        <div className="ongoaing-job-status-main overflow-x-auto">
                                            {
                                                returnArray(jobs.results).length === 0 &&
                                                <div className="inner-content-wrapper text-center">
                                                    <div className="img-wrapper">
                                                        <img src={noOngoingJobs} alt="noJobImg" />
                                                    </div>
                                                    <div className="text-wrapper">
                                                        <strong className="dark medium">No jobs on your radar yet!</strong>
                                                        <p>Browse available opportunities and start applying to bring in <br /> exciting projects.</p>
                                                        <Link to="/dashboard/find-jobs" className="btn-style blue-border">Find jobs</Link>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                returnArray(jobs.results).map((job, index) => (
                                                    <div className="ongoing-job-status">
                                                        <div className="job-info">
                                                            <div className="img-wrapper">
                                                                <a href="#"><img src={returnArray(job?.image)?.[0] ? parseImagePathToURL(job?.image[0]) : jobImg1} alt="jobImg1" className="job-img" /></a>
                                                            </div>
                                                            <div className="text-wrapper">
                                                                <strong className="dark "><a href="#">{job?.jobTitle}</a></strong>
                                                            </div>
                                                        </div>
                                                        <div className="states-main-wrapper">
                                                            <div className="stats">
                                                                <div className="text blue">
                                                                    <CircularProgressbar value={20} strokeWidth={16} />
                                                                    <span className="white">  2/5</span>
                                                                </div>
                                                                <div className="bottom">
                                                                    <img src={videofile} alt="videofile" />
                                                                    <span className="ex_small">Videos Created</span>
                                                                </div>
                                                            </div>
                                                            <div className="stats">
                                                                <div className="text pink">
                                                                    <CircularProgressbar value={20} strokeWidth={16} />
                                                                    <span className="white"> 2/5</span>
                                                                </div>
                                                                <div className="bottom">
                                                                    <img src={liveIcon} alt="liveIcon" />
                                                                    <span className="ex_small">Livestreams</span>
                                                                </div>
                                                            </div>
                                                            <div className="stats">
                                                                <div className="text gray">
                                                                    <span className="">7</span>
                                                                </div>
                                                                <div className="bottom">
                                                                    <FaCalendarAlt />
                                                                    <span className="ex_small">Days to deadline</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 mb-4">
                                <div className="job-info-wrapper">
                                    <div className="title-wrapper">
                                        <strong className="large dark">My Messages</strong>
                                        <div className="info-icon-wrapper">
                                            <Link to="/dashboard/messages">
                                                <img src={arrowInBoxIcon} alt="arrowInBoxIcon" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="inner-content-wrapper">
                                        <div className="dashboard-home-my-messages">
                                            <DashboardMyMessages />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-7 mb-4">
                            <div className="job-info-wrapper">
                                <div className="title-wrapper">
                                    <strong className="large dark">Trends</strong>
                                    <div className="info-icon-wrapper">
                                        <a href="#">
                                            <img src={arrowInBoxIcon} alt="arrowInBoxIcon" />
                                        </a>
                                    </div>
                                </div>
                                <DashboardTrendsChartComponent trendActiveStateHandler={trendActiveStateHandler} trendActiveState={trendActiveState} />
                                {trendActiveState === 'gmv' && <TrendLineChart />}
                                {trendActiveState === 'itemSold' && <TrendLineChart />}
                                {trendActiveState === 'videoGpm' && <TrendLineChart />}
                                {trendActiveState === 'liveViews' && <TrendLineChart />}
                                {trendActiveState === 'videoViews' && <TrendLineChart />}
                                {trendActiveState === 'liveGpm' && <TrendLineChart />}

                            </div>
                        </div> */}
                            {/* <div className="col-md-5 mb-4">
                            <div className="job-info-wrapper">
                                <div className="inner-content-wrapper">
                                    <div className='dashboard-home-service-price'>
                                        <DashboardServicePrice servicePriceVideoCardsArray={returnArray(creator?.videoPrices)} servicePriceLiveCardsArray={returnArray(creator.livePrices)} />
                                        <br />
                                        <div className="creator-dashboard-states-wrapper">
                                            <div className="creator-states-info-wrapper-with-chart">
                                                <div className="main-wrapper">
                                                    <div className="img-wrapper">
                                                        <img src={pieChartFill} alt="pieChartFill" />
                                                    </div>
                                                    <div className="content-wrapper">
                                                        <div className="title">
                                                            <span className="small medium-bold">My RO I</span>
                                                            <HiInformationCircle />
                                                        </div>
                                                        <div className="num">
                                                            <strong className="large dark">$120</strong>
                                                            <IoMdTrendingDown color="#F93C65" />
                                                            <IoMdTrendingUp color="#00B69B" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="chart-wrapper">
                                                    <SmallStatesChart />
                                                </div>
                                            </div>
                                            <div className="creator-states-info-wrapper-with-chart ">
                                                <div className="main-wrapper">
                                                    <div className="img-wrapper light-blue">
                                                        <img src={myGvmICon} alt="pieChartFill" />
                                                    </div>
                                                    <div className="content-wrapper">
                                                        <div className="title">
                                                            <span className="small medium-bold">My GMV</span>
                                                            <HiInformationCircle />
                                                        </div>
                                                        <div className="num">
                                                            <strong className="large dark">$120</strong>
                                                            <IoMdTrendingUp color="#00B69B" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="chart-wrapper">
                                                    <SmallStatesChart />
                                                </div>
                                            </div>
                                            <div className="creator-states-info-wrapper-with-chart">
                                                <div className="main-wrapper">
                                                    <div className="img-wrapper light-pink">
                                                        <img src={gvmPerVideo} alt="pieChartFill" />
                                                    </div>
                                                    <div className="content-wrapper">
                                                        <div className="title">
                                                            <span className="small medium-bold">My GMV Per Video</span>
                                                            <HiInformationCircle />
                                                        </div>
                                                        <div className="num">
                                                            <strong className="large dark">$120</strong>
                                                            <IoMdTrendingDown color="#F93C65" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="chart-wrapper">
                                                    <SmallStatesChart />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
                <RecommendedJobCardComponent />
                <JobPostModalComponent
                    show={modalState}
                    onHide={() => setModalState(false)}
                    modalCloseHandler={modalCloseHandler}
                />
            </div>
        </div>
    )
}