import React from "react";
import { ErrorMessage, Field } from "formik";

export default function JobPostUGCRate({ errors }) {
  return (
    <div className="job-post-inner-wrapper">
      <div>
        <div className="modal-text-wrapper">
          <h2>Set Your UGC Rate</h2>
          <p className="medium medium-bold">
            Your phone number lets us keep you updated on job opportunities,{" "}
            <br /> payments, and important notifications.
          </p>
        </div>
        <div className="form-group with-label-icon">
          <label htmlFor="ugc_price" className="medium">
            UGC Rate
          </label>
          <span className="input-icon">$</span>
          <Field
            type="text"
            id="ugc_price"
            name="ugc_price"
            className={`form-control blue-text ${
              errors.ugc_price ? "is-invalid" : ""
            }`}
            placeholder="0"
          />
        </div>
        <ErrorMessage
          component="div"
          className="invalid-feedback d-block"
          name="ugc_price"
          style={{ marginTop: "-10px" }}
        />
        {/* {touched.ugc_price && errors.ugc_price && (
          <div
            className="invalid-feedback d-block"
            style={{ marginTop: "-10px" }}
          >
            {errors.ugc_price}
          </div>
        )} */}
      </div>
    </div>
  );
}
