import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../detectError";
import { authenticateAsyncThunk, setCreator, storeProfile } from "./authSlice";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
const toast = { error: () => {}, success: () => {} };
// Start Payment Slices
///////////////////////////////////////////////////

export const checkoutAsyncThunk = createAsyncThunk(
  "payment/checkoutAsyncThunk",
  catchAsync(async ({ data }) => {
    const response = await ApiRequests.checkout({ ...data });
    return response?.data;
  })
);

export const connectAccountAsyncThunk = createAsyncThunk(
  "payment/connectAccountAsyncThunk",
  catchAsync(async ({ data, callback }, { dispatch, getState }) => {
    const response = await ApiRequests.connectAccount(data);
    if (response.status === 201) {
      dispatch(
        storeProfile({
          ...getState().auth.profile,
          stripe_account_id: response?.data,
        })
      );
      dispatch(
        setCreator({
          ...getState().auth.creator,
          stripe_account_id: response?.data,
        })
      );

      dispatch(getConnectedAccountAsyncThunk({ id: response?.data }));
    }
    if (callback) callback();
    return response?.data;
  })
);

export const getConnectedAccountAsyncThunk = createAsyncThunk(
  "payment/getConnectedAccountAsyncThunk",
  catchAsync(async ({ id, callback }) => {
    const response = await ApiRequests.getConnectedAccount(id);
    if (callback) callback();
    return response?.data;
  })
);

export const accountOnboardingAsyncThunk = createAsyncThunk(
  "payment/accountOnboardingAsyncThunk",
  catchAsync(async ({ id, callback }) => {
    const response = await ApiRequests.accountOnboarding(id);
    if (callback) callback();
    return response?.data;
  })
);

const initialState = {
  //news states
  story: null,
  assets: null,
  asset: null,
  subscriptions: null,
  analytics: null,
  listings: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  connectedAccount: {},
  order: "asce",
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConnectedAccountAsyncThunk.pending, (state, action) => {
        state.connectedAccount = {};
      })
      .addCase(getConnectedAccountAsyncThunk.fulfilled, (state, action) => {
        state.connectedAccount = action.payload;
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            checkoutAsyncThunk,
            connectAccountAsyncThunk,
            getConnectedAccountAsyncThunk,
            accountOnboardingAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default paymentSlice.reducer;
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  paymentSlice.actions;
