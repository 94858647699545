import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../detectError";
import { toast } from "react-toastify";

// Thunks for async actions
export const fetchUsersWithRoomAsyncThunk = createAsyncThunk(
  "chat/fetchUsersWithRoomAsyncThunk",
  catchAsync(async () => {
    const response = await ApiRequests.getUsersWithRoom();
    if (response.status === 200) {
      // toast.success('Users with rooms fetched successfully!');
    }
    return response?.data;
  })
);

export const fetchRoomsAsyncThunk = createAsyncThunk(
  "chat/fetchRoomsAsyncThunk",
  catchAsync(async () => {
    const response = await ApiRequests.getUserRooms();
    if (response.status === 200) {
      // toast.success('Rooms fetched successfully!');
    }
    return response?.data;
  })
);

export const fetchMessagesAsyncThunk = createAsyncThunk(
  "chat/fetchMessagesAsyncThunk",
  catchAsync(async ({ selectedRoom, page }) => {
    const roomId = selectedRoom?._id || selectedRoom?.id;
    if (!roomId) return { results: [], totalPages: 0, totalResults: 0 };
    const response = await ApiRequests.getRoomMessages(roomId, {
      limit: 15,
      page,
    });
    if (response.status === 200) {
      // toast.success('Messages fetched successfully!');
    }
    return response?.data;
  })
);

export const sendFileAsyncThunk = createAsyncThunk(
  "chat/sendFileAsyncThunk",
  catchAsync(async ({ data, callBack }) => {
    const response = await ApiRequests.sendFile(data);

    if (response.status === 201) {
      // toast.success('File sent successfully!');
    }
    if (callBack) callBack(response?.data);

    return response?.data;
  })
);

export const saveMessageAsyncThunk = createAsyncThunk(
  "chat/saveMessageAsyncThunk",
  catchAsync(async ({ messageData, callBack }) => {
    const response = await ApiRequests.saveMessage(messageData);

    if (response.status === 200) {
      // toast.success('Message saved successfully!');
    }
    if (callBack) callBack(response?.data);

    return response?.data;
  })
);

export const readUnreadMessagesAsyncThunk = createAsyncThunk(
  "chat/readUnreadMessagesAsyncThunk",
  catchAsync(async ({ roomId, callBack }) => {
    const response = await ApiRequests.readUnreadMessages({ roomId });

    if (response.status === 200) {
      // toast.success('Messages read/unread successfully!');
    }
    if (callBack) callBack(response?.data);

    return response?.data;
  })
);

export const markAllUnreadMessagesAsyncThunk = createAsyncThunk(
  "chat/markAllUnreadMessagesAsyncThunk",
  catchAsync(async ({ roomIds, callback }, { dispatch }) => {
    const response = await ApiRequests.markAllAsRead({ roomIds });

    if (callback) callback();
    if (response.status === 201) {
      // toast.success('Messages read/unread successfully!');
      dispatch(fetchRoomsAsyncThunk());
    }

    return response?.data;
  })
);

export const deleteMessageAsyncThunk = createAsyncThunk(
  "chat/deleteMessageAsyncThunk",
  catchAsync(async ({ messageId, callBack }) => {
    const response = await ApiRequests.deleteMessage(messageId);

    if (response.status === 204) {
      toast.success("Message deleted successfully!");
    }
    if (callBack) callBack(response?.data);

    return response?.data;
  })
);

export const deleteRoomAsyncThunk = createAsyncThunk(
  "chat/deleteRoomAsyncThunk",
  catchAsync(async ({ roomId, callBack }) => {
    const response = await ApiRequests.deleteRoom(roomId);

    if (response.status === 200) {
      // toast.success('Room deleted successfully!');
    }
    if (callBack) callBack(response?.data);

    return response?.data;
  })
);

export const getSystemRoomAsyncThunk = createAsyncThunk(
  "chat/getSystemRoomAsyncThunk",
  catchAsync(async () => {
    const response = await ApiRequests.getSystemRoom();
    return response?.data;
  })
);

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    users: [],
    rooms: [],
    currentUser: null,
    systemRoom: null,
    messages: {
      results: [],
      page: 1,
      totalPages: 0,
      totalResults: 0,
    },
    notifications: [],
    errors: {},
    loadings: {},
    errorMessages: {},
    errorCodes: {},
    paramsForThunk: {},
  },
  reducers: {
    setNotifications: (state, action) => {
      const newNotification = action.payload;
      // Check if the notification already exists based on a unique identifier (e.g., id)
      const exists = state.notifications.find(
        (notification) => notification.id === newNotification.id
      );
      // Only add the notification if it doesn't already exist
      if (!exists) {
        state.notifications = [newNotification, ...state.notifications];
      }
    },
    setRooms: (state, action) => {
      const unreadMessages = action.payload?.filter(
        (room) => room.unreadMessagesCount > 0
      );
      state.notifications = [
        ...unreadMessages.map((room) => ({
          ...room,
          notificationType: "room_read",
        })),
      ];
      state.rooms = action.payload;
    },
    setMessages: (state, action) => {
      state.messages.results = action.payload;
    },
    setIsFileSending: (state, action) => {
      state.isFileSending = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersWithRoomAsyncThunk.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(fetchRoomsAsyncThunk.fulfilled, (state, action) => {
        state.rooms = action.payload;
        const unreadMessages = action.payload?.filter(
          (room) => room.unreadMessagesCount > 0
        );
        // console.log("🚀 ~ .addCase ~ unreadMessages:", unreadMessages)
        state.notifications = [
          ...unreadMessages.map((room) => ({
            ...room,
            notificationType: "room_read",
          })),
        ];
      })
      .addCase(fetchMessagesAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.messages = {
            ...action.payload,
            results: state?.messages?.results.concat(action?.payload?.results),
          };
        } else {
          state.messages = action.payload;
        }
      })
      .addCase(sendFileAsyncThunk.pending, (state) => {
        state.isFileSending = true;
      })
      .addCase(sendFileAsyncThunk.fulfilled, (state, action) => {
        state.file = action.payload.key;
        state.isFileSending = false;
      })
      .addCase(sendFileAsyncThunk.rejected, (state) => {
        state.isFileSending = false;
      })
      // .addCase(deleteMessageAsyncThunk.fulfilled, (state, action) => {
      //     const { messageId } = action.payload;
      //     state.messages.results = state.messages?.results?.filter(msg => (msg.id || msg._id) !== messageId);
      // })
      .addCase(getSystemRoomAsyncThunk.fulfilled, (state, action) => {
        state.systemRoom = action.payload;
      })

      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            fetchUsersWithRoomAsyncThunk,
            fetchMessagesAsyncThunk,
            sendFileAsyncThunk,
            saveMessageAsyncThunk,
            deleteMessageAsyncThunk,
            deleteRoomAsyncThunk,
            fetchRoomsAsyncThunk,
            readUnreadMessagesAsyncThunk,
            markAllUnreadMessagesAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default chatSlice.reducer;

export const {
  setActiveChatState,
  setUsers,
  setRooms,
  setRoomLoading,
  setTotalResults,
  setSelectedRoom,
  setMessages,
  setCurrentUser,
  setUser,
  setIsLoading,
  setPage,
  setTotalPages,
  setIsInfiniteLoading,
  setFile,
  setIsFileSending,
  setShowFileInput,
  setShowModal,
  setSelectedMedia,
  setMessageText,
  setChannel,
  setPresenceData,
} = chatSlice.actions;
