import React from "react";

import videoFileicon from '../assets/images/icons/video-file-wrapper.svg'
import refreshIcon from '../assets/images/icons/refresh-icon.svg'
import pointerIcon from '../assets/images/icons/pointer-icon.svg'
import liveIcon from '../assets/images/icons/live-icon.svg'
import { returnArray } from "../utils/utils";

export default function VideoLivePricingsListsComponent({
    livePrices = [],
    videoPrices = [],
    setVideoPrices = () => { },
    setLivePrices = () => { },
    offeredVideoPrice = [],
    setOfferedVideoPrice = () => { },
    offeredLivePrice = [],
    setOfferedLivePrice = () => { },
    resetVidePrices = () => { },
    resetLivePrices = () => { },
}) {
    return (
        <div className="video-live-pricing-lists-wrapper">
            <div className="shop-rates-list-main-wrapper">
                <div className="main-wrapper">
                    <div className="title-wrapper">
                        <div className="title">
                            <img src={videoFileicon} alt="videoFileicon" />
                            <strong className="medium">Video Prices</strong>
                        </div>
                        <img src={refreshIcon} onClick={resetVidePrices} alt="refreshIcon" />
                    </div>
                    <div className="main-list-wrapper">
                        {
                            returnArray(videoPrices).map((price, index) => (
                                <div className="list" key={index}>
                                    <div className="custom-checkbox-wrapper">
                                        <label class="checkbox-label">
                                            <span><span className="bold">{price.count}</span> Videos per month</span>
                                            <input
                                                type="checkbox"
                                                checked={offeredVideoPrice?.includes(price._id)}
                                                onChange={() => setOfferedVideoPrice(prev => {
                                                    if (prev.includes(price._id)) {
                                                        return prev.filter(id => id !== price._id)
                                                    } else {
                                                        return [...prev, price._id]
                                                    }
                                                }
                                                )} />

                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="price-wrapper">
                                        <div className="price">
                                            <span>$</span>
                                            <input
                                                type="number"
                                                onChange={(e) => {
                                                    const newPrice = +e.target.value;
                                                    if (typeof newPrice === "number" && newPrice < 0) return;
                                                    setVideoPrices((prevPrices) =>
                                                        prevPrices.map((v) => {
                                                            if (v._id === price._id) {
                                                                return { ...v, price: newPrice };
                                                            }
                                                            return v;
                                                        })
                                                    );
                                                }}
                                                placeholder={price.price}
                                                value={price.price}
                                            />

                                        </div>
                                        <img src={pointerIcon} alt="pointerIcon" className="pointer-icon" />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="main-wrapper">
                    <div className="title-wrapper">
                        <div className="title">
                            <img src={liveIcon} alt="liveIcon" />
                            <strong className="medium">Live Prices <span> (At least 1 hour)</span></strong>
                        </div>
                        <img src={refreshIcon} onClick={resetLivePrices} alt="refreshIcon" />
                    </div>
                    <div className="main-list-wrapper">
                        {
                            returnArray(livePrices).map((price, index) => (
                                <div className="list" key={index}>
                                    <div className="custom-checkbox-wrapper">
                                        <label class="checkbox-label">
                                            <span><span className="bold">{price.count}</span> Lives per month</span>
                                            <input type="checkbox"
                                                checked={offeredLivePrice?.includes(price._id)}
                                                onChange={() => setOfferedLivePrice(prev => {
                                                    if (prev.includes(price._id)) {
                                                        return prev.filter(id => id !== price._id)
                                                    } else {
                                                        return [...prev, price._id]
                                                    }
                                                }
                                                )}
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="price-wrapper">
                                        <div className="price">
                                            <span>$</span>
                                            <input type="number"
                                                onChange={(e) => {
                                                    const newPrice = +e.target.value;
                                                    setLivePrices((prevPrices) =>
                                                        prevPrices.map((v) => {
                                                            if (v._id === price._id) {
                                                                return { ...v, price: newPrice };
                                                            }
                                                            return v;
                                                        })
                                                    );
                                                }}
                                                placeholder={price.price}
                                                value={price.price}
                                            />
                                        </div>
                                        <img src={pointerIcon} alt="pointerIcon" className="pointer-icon" />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}