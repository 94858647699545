import { IoClose } from "react-icons/io5";
import ToolTipImageSliderComponent from "../components/ToolTipImageSliderComponent";
import tooltipImg from '../assets/images/toolt-tip-img.png'
import { useTour } from "@reactour/tour";

const toolTipImageArray =  [
    tooltipImg,
    tooltipImg,
    tooltipImg,
    tooltipImg,
]

const CustomTooltip = ({ title, description }) => {
    const { currentStep, next, prev, close, steps } = useTour(); // Access tour controls
  
    return (
      <div className="header-tooltip-main-wrapper">
        <div className="text-wrapper">
          <strong className="medium">{title}</strong>
          <p>{description}</p>
        </div>
        <div className="toltip-slider-wrapper">
        <div>
                    <img src={tooltipImg} alt="tooltipImg" className='tooltip-slider-img' />
        </div>
        </div>
        {/* <div className="tooltip-footer-wrapper">
          <button
            className="btn-style text"
            onClick={() => {
              if (currentStep > 0) prev();
            }}
          >
            Back
          </button>
          <button
            className="btn-style"
            onClick={() => {
              if (currentStep < steps.length - 1) next();
            }}
          >
            Next
          </button>
        </div>
        <button className="cross-btn" onClick={close}>
          <IoClose />
        </button> */}
      </div>
    );
  };

export const tourSteps = [
    {
      selector: ".find-jobs-tooltip",
      content: <CustomTooltip title="Job Feed" description="Explore job listings tailored to your niche and interests." />,
    },
    {
      selector: ".my-proposals-tooltip",
      content: <CustomTooltip title="Proposals" description="Monitor all proposals you’ve submitted to brands.
See proposal status, respond to offers, and track follow-up actions." />,
    },
    {
      selector: ".my-jobs-tooltip",
      content: <CustomTooltip title="My Jobs" description="Manage all your active and completed jobs here. Track your job statuses, view deadlines, and access job details." />,
    },
    {
      selector:".profile-tooltip",
      content : <CustomTooltip title="Profile Menu" description="Access your Profile Settings to update account details and preferences, reach Help & Support for assistance, or securely Logout of your account."/>
    }
  ];


  
