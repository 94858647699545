import React from "react";
import { ErrorMessage, Field } from "formik";

export default function JobPostWhatMakesYou({ errors, values }) {
  return (
    <div className="job-post-inner-wrapper">
      <div>
        <div className="modal-text-wrapper">
          <h2>Tell Us What Makes You, You!</h2>
          <p className="medium medium-bold">
            Share something special about your personality or content style.{" "}
            <br />
            Brands love to know what makes creators stand out.
          </p>
        </div>
        <div className="form-group">
          <label htmlFor="bio" className="medium">
            What’s unique about you
          </label>
          <Field
            as="textarea"
            name="bio"
            id="bio"
            className={`form-control blue-text ${
              errors.bio ? "is-invalid" : ""
            }`}
            placeholder="Tell us what’s unique about you"
            rows={4}
            value={values.bio}
          />
          <ErrorMessage
            component="div"
            className="invalid-feedback"
            name="bio"
          />
        </div>
      </div>
    </div>
  );
}
