import React, { useState } from "react";

import beachImg from "../../assets/images/beach-img.png";
import SendPhotoModal from "../SendPhotoModal";

export default function SentImageComponent({ imageSrc, formateTime }) {
  const [sendPhotoModal, setSendPhotoModal] = useState(false);
  return (
    <div className="sent-image-wrapper">
      <div className="img-wrapper">
        <img src={imageSrc || beachImg} alt="beachImg" className="sent-img" />
        <span className="time">{formateTime} </span>
      </div>
      <SendPhotoModal
        sendPhotoModal={sendPhotoModal}
        sendPhotoModalCloseHandler={() => setSendPhotoModal(false)}
        isSend={false}
        photoURL={imageSrc}
      />
    </div>
  );
}
