import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";
import { LuEyeOff, LuEye } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { userUpdatePasswordAsyncThunk } from "../../redux/pagesSlices/authSlice";
import { Spinner } from "react-bootstrap";

const passwordValidationSchema = Yup.object().shape({
  currentPass: Yup.string().required("Current password is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[@$!%*?&#]/,
      "Password must contain at least one special character"
    ),
  passwordConfirm: Yup.string()
    .required("Password confirmation is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});
export default function ChangePasswodModal({
  currentPass,
  isOpen,
  handleClose,
}) {
  const { loadings } = useSelector((s) => s.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const d = useDispatch();
  console.log("loadings", loadings);

  const handleSubmit = (values, { resetForm }) => {
    d(
      userUpdatePasswordAsyncThunk({
        data: {
          currentPassword: values.currentPass,
          password: values.password,
        },

        callBack: () => {
          handleClose();
          resetForm();
        },
      })
    );
  };

  return (
    <div className="change-password-modal-wrapper">
      <Modal
        show={isOpen}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-modal change-password-modal"
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            currentPass: currentPass || "",
            password: "",
            passwordConfirm: "",
          }}
          validationSchema={passwordValidationSchema}
          onSubmit={handleSubmit}
        >
          {({}) => {
            return (
              <>
                <Form className="form">
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Change Password
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="modal-inner-wrapper">
                      <div className="field-wrapper">
                        <div className="form-group">
                          <label htmlFor="">Current password</label>
                          <Field
                            type="text"
                            name="currentPass"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="currentPass"
                            component="div"
                            className="error-msg"
                          />
                        </div>
                      </div>
                      <div className="field-wrapper">
                        <div className="form-group with-right-icon">
                          <label htmlFor="">New password</label>
                          <Field
                            type={showPassword ? "text" : "password"}
                            name="password"
                            className="form-control"
                          />
                          {showPassword ? (
                            <LuEye
                              onClick={() => setShowPassword(!showPassword)}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <LuEyeOff
                              onClick={() => setShowPassword(!showPassword)}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error-msg"
                        />
                      </div>
                      <div className="field-wrapper">
                        <div className="form-group with-right-icon">
                          <label htmlFor="">Confirm password</label>
                          <Field
                            type={showConfirmPassword ? "text" : "password"}
                            name="passwordConfirm"
                            className="form-control"
                          />
                          {showConfirmPassword ? (
                            <LuEye
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <LuEyeOff
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </div>
                        <ErrorMessage
                          name="passwordConfirm"
                          component="div"
                          className="error-msg"
                        />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      onClick={handleClose}
                      className="btn-style transparent"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={loadings?.userUpdatePasswordAsyncThunk}
                      className="btn-style"
                    >
                      {loadings?.userUpdatePasswordAsyncThunk ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </Modal.Footer>
                </Form>
              </>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}
