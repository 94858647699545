import React from "react";
import PropTypes from "prop-types";
import noJobsImg from "../assets/images/icons/no-jobs-img.svg";
import { Link } from "react-router-dom";

export default function NoDataComponent({
    imageSrc = noJobsImg,
    imageAlt = "No data illustration",
    title = "No Jobs Yet? Let’s Get Started!",
    description = "It looks like you haven’t applied for any jobs yet. Explore new opportunities and start your content creation journey with BMC.",
    primaryActionText = "Discover Jobs",
    primaryActionLink = "#",
    secondaryActionText = "How it Works",
    secondaryActionLink = "#",
}) {
    return (
        <div className="no-jobs-main-wrapper">
            <div className="main-wrapper">
                <div className="img-wrapper">
                    <img src={imageSrc} alt={imageAlt} className="no-jobs-img" />
                </div>
                <div className="content-wrapper">
                    <strong className="dark large">{title}</strong>
                    <p>{description}</p>
                    <div className="btn-wrapper">
                        <Link to={primaryActionLink} className="btn-style">
                            {primaryActionText}
                        </Link>
                        <Link to={secondaryActionLink} className="btn-style transparent light">
                            {secondaryActionText}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

// Define PropTypes for the component
NoDataComponent.propTypes = {
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    primaryActionText: PropTypes.string,
    primaryActionLink: PropTypes.string,
    secondaryActionText: PropTypes.string,
    secondaryActionLink: PropTypes.string,
};
