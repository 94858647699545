import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

export default function BroadCastModal({ broadCastModalCloseHandler, boardCastModalState }) {
    return (
        <div className='braodcast-modal-wrapper'>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={boardCastModalState}
                className="braodcast-modal custom-modal"
                onHide={broadCastModalCloseHandler}
            >
                <Modal.Header boardCastModalState>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="main-wrappr">
                        <div className="content-wrapper text-start">
                            <h3>Broadcast group</h3>
                        </div>
                    </div>
                    <form action="#" className="form">
                        <div className="form-group">
                            <label htmlFor="Name">Name</label>
                            <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="add-member">Add Members</label>
                            <input type="text" className="form-control" placeholder='Search to add' />
                        </div>
                        <div className="form-group">
                            <label htmlFor="Description">Description (Optional)</label>
                            <textarea className="form-control" name="Description" id="Description"></textarea>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-wrapper">
                        <a href="#" className="blue" onClick={broadCastModalCloseHandler}>Cancel</a>
                        <a href="#" className="btn-style">Create</a>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
