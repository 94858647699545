import React from "react";
import Userfill from "../assets/images/icons/Userfill.svg"
import Imgboxfill from "../assets/images/icons/Imgboxfill.svg"
import Chartalt from "../assets/images/icons/Chartalt.svg"
import Chartfill from "../assets/images/icons/Chartalt.svg"

const MyProfileTabsComponent = ({ activeTab, getButtonClass }) => {
  
 

  return (
    <div className="header-bottom-container">
      <button className={`header-bottom-btn ${activeTab === 'tab1' ? 'active' : '' }` } onClick={() => getButtonClass("tab1")}>
        <img src={Userfill} alt="Userfill" />
        <span>Profile overview</span>
      </button>
      <button className={`header-bottom-btn ${activeTab === 'tab2' ? 'active' : '' }` } onClick={() => getButtonClass("tab2")}>
        <img src={Imgboxfill} alt="Imgboxfill" />
        <span>Portfolio </span>
      </button>
      <button className={`header-bottom-btn ${activeTab === 'tab3' ? 'active' : '' }` } onClick={() => getButtonClass("tab3")}>
        <img src={Chartfill} alt="Chartfill" />
        <span>Analytics & Insights</span>
      </button>
      <button className={`header-bottom-btn ${activeTab === 'tab4' ? 'active' : '' }` } onClick={() => getButtonClass("tab4")}>
        <img src={Chartalt} alt="Chartalt" />
        <span>Services Offered</span>
      </button>
    </div>
  );
};

export default MyProfileTabsComponent;
