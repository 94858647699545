import React, { useEffect, useState } from "react";
import MyJobsPageHeader from "../components/MyJobsPageHeader";
import NoJobsComponent from "../components/NoJobsComponent";
import { useDispatch, useSelector } from "react-redux";
import { getHiredJobsAsyncThunk } from "../redux/pagesSlices/jobSlice";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";
import MyJob from "../components/MyJob";
import { getMyApplicationsCountAsyncThunk } from "../redux/pagesSlices/applicationSlice";
import noJobsImg from '../assets/images/icons/no-jobs-img.svg'

export default function MyJobsPage() {
  const [activeJobsTab, setActiveJobsTabs] = useState("all");
  const activeJobTabsHandler = (tab) => {
    setActiveJobsTabs(tab);
  };
  const dispatch = useDispatch();

  const params = useSelector(
    (s) => s.job?.paramsForThunk?.getHiredJobsAsyncThunk
  );
  const { applicationsCount } = useSelector((s) => s.application);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("createdAt:desc");

  useEffect(() => {
    dispatch(getHiredJobsAsyncThunk({ sortBy, populate: "brandId" }));
    dispatch(getMyApplicationsCountAsyncThunk());
  }, [dispatch]);

  useEffect(() => {
    // wait user stop typing
    const timeout = setTimeout(() => {
      const reqParams = {
        ...(params ? params : {}),
        ...(search ? { jobTitle: search } : {}),
        sortBy,
        populate: "brandId",
        ...(activeJobsTab && activeJobsTab !== "all"
          ? { jobStatus: activeJobsTab }
          : {}),
      };
      dispatch(getHiredJobsAsyncThunk(reqParams));
    }, 500);
    return () => clearTimeout(timeout);
  }, [search, sortBy, activeJobsTab, params, dispatch]);

  const Tabs = () => (
    <div className="top-bar-wrapper">
      <ul className="tabs-wrapper">
        <li>
          <button
            className={`custom-btn ${activeJobsTab === "all" ? "active" : ""
              }`}
            onClick={() => activeJobTabsHandler("all")}
          >
            All{" "}
            <span className="num">
              {applicationsCount?.totalCount || 0}
            </span>
          </button>{" "}
        </li>
        <li>
          <button
            className={`custom-btn ${activeJobsTab === "in_progress" ? "active" : ""
              }`}
            onClick={() => activeJobTabsHandler("in_progress")}
          >
            In Progress{" "}
            <span className="num">
              {applicationsCount?.statusCounts?.in_progress || 0}
            </span>
          </button>
        </li>
        <li>
          <button
            className={`custom-btn ${activeJobsTab === "completed" ? "active" : ""
              }`}
            onClick={() => activeJobTabsHandler("completed")}
          >
            Completed{" "}
            <span className="num">
              {applicationsCount?.statusCounts?.completed || 0}
            </span>
          </button>
        </li>
      </ul>
    </div>
  )
  const Parent = ({ children }) => (
    <div className="inner-page-section">
      <Tabs />
      <div className="my-jobs-listings ">
        <div className="table-wrapper over_flow">
          <div className="my-proposals-listings">
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th
                      style={{
                        textAlign: "start",
                        paddingLeft: 133,
                      }}
                    >
                      <strong className="white small">
                        Brand Name
                      </strong>
                    </th>
                    <th style={{ textAlign: "start" }}>
                      <strong className="white small">Status</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>{children} </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  const LoaderParent = ({ children }) => (
    <div className="inner-page-section">
      <Tabs />
      <div className="my-jobs-listings ">
        <div className="table-wrapper over_flow">
          <div className="my-proposals-listings">
            <div className="table-wrapper">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )


  return (
    <div className="inner-page-wrapper active m-0">
      <div className="my-jobs-page">
        <MyJobsPageHeader
          search={search}
          sortBy={sortBy}
          handleSearchChange={(e) => setSearch(e.target.value)}
          title={"My Jobs"}
          containerFluid={true}
          setSortBy={setSortBy}
        />
        {/* {hasListings ? ( */}
        <>
          {/* <MyJobsListings tab={activeJobsTab} /> */}

          <ProviderErrorLoadingPagination
            reducer="job"
            action="getHiredJobsAsyncThunk"
            asyncThunk={getHiredJobsAsyncThunk}
            dataKey="hiredJobs"
            Component={MyJob}
            emptyMessage="No jobs found!"
            emptyComponent={<NoJobsComponent className={"w-100"} image={noJobsImg} title={"No Jobs Yet? Let’s Get Started!"} description={"It looks like you haven’t applied for any jobs yet. Explore new opportunities and start your content creation journey with BMC."} btnText={"Discover Jobs"} route={"/dashboard/find-jobs"}/>}
            pagination
            Parent={Parent}
            LoaderParent={LoaderParent}
            itemKey="application"
          />
        </>
      </div>
    </div>
  );
}
