import React from "react";
import settingIcon from "../assets/images/icons/settings-light-icon.svg";
import xIcon from "../assets/images/icons/x-icon.svg";
import avatarIcon from "../assets/images/icons/avatar-icon.svg";
import NoNotificationComponent from "./NoNotificationComponent";
import moment from "moment";
import { returnArray } from "../utils/utils";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { markAllUnreadMessagesAsyncThunk } from "../redux/pagesSlices/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function NotificationComponent({
  notifications,
  showNotification,
  hideNotificationsHandler,
  notificationRef,
}) {
  const { loadings } = useSelector((s) => s.chat);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function decodeHTML(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  const today = moment().startOf("day");

  const todayNotifications = notifications?.filter((n) =>
    moment(n.lastMessageTimestamp).isSame(today, "day")
  );
  const olderNotifications = notifications?.filter((n) =>
    moment(n.lastMessageTimestamp).isBefore(today, "day")
  );

  const handleReadAll = () => {
    const notificationsToRead = returnArray(notifications).filter(
      (item) => item.unreadMessagesCount > 0
    );

    const roomIds = notificationsToRead.map((item) => item._id);
    if (!roomIds || roomIds.length === 0) return;

    dispatch(
      markAllUnreadMessagesAsyncThunk({
        roomIds,
        callback: () => {
          hideNotificationsHandler();
        },
      })
    );
  };
  return (
    <div
      className={`notification-main-wrapper ${showNotification ? "show" : ""}`}
      ref={notificationRef}
    >
      <div className="title-wrapper">
        <strong className="dark large medium-bold">Notifications</strong>
        <ul className="list">
          <li>
            <a href="#">
              <img src={settingIcon} alt="settingIcon" />
            </a>
          </li>
          <li>
            <a href="#" onClick={hideNotificationsHandler}>
              <img src={xIcon} alt="xIcon" />
            </a>
          </li>
        </ul>
      </div>
      <div className="notification-list-wrapper">
        {returnArray(notifications)?.length > 0 ? (
          <>
            <div className="notifications-wrapper">
              <div className="inner-wrapper">
                <div className="notification-list-title-wrapper ">
                  <span>TODAY</span>
                  <div className="right-menu-wrapper">
                    <span className="small">Show only unread</span>
                    {loadings?.markAllUnreadMessagesAsyncThunk ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <a href="#" className="blue" onClick={handleReadAll}>
                        Mark all as read
                      </a>
                    )}
                  </div>
                </div>
                <div className="all-listings-wrapper">
                  {returnArray(todayNotifications)?.map((item, i) => (
                    <div
                      className={`notification-wrapper ${
                        item?.unreadMessagesCount > 0 ? "notifications" : ""
                      } `}
                      key={i}
                      onClick={() => navigate("/dashboard/messages")}
                    >
                      <div className="avatar-icon">
                        <a href="#">
                          <img
                            src={
                              item?.user?.avatar
                                ? parseImagePathToURL(item.user.avatar)
                                : avatarIcon
                            }
                            alt="avatarIcon"
                          />
                        </a>
                      </div>
                      <div className="text-wrapper">
                        <strong className="dark">
                          <a href="#">
                            {decodeHTML(
                              item?.lastMessage || item.room?.lastMessage
                            )}
                          </a>
                        </strong>
                        <span className="time">
                          {item?.lastMessageTimestamp
                            ? moment(item.lastMessageTimestamp).fromNow()
                            : "Just now"}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="notification-list-title-wrapper ">
                  <span>OLDER</span>
                </div>
                <div className="all-listings-wrapper">
                  {returnArray(olderNotifications)?.map((item, i) => {
                    return (
                      <div
                        className={`notification-wrapper ${
                          item?.unreadMessagesCount > 0 ? "notifications" : ""
                        } `}
                        key={i}
                        onClick={() => navigate("/dashboard/messages")}
                      >
                        <div className="avatar-icon">
                          <a href="#">
                            <img
                              src={
                                item?.user?.avatar
                                  ? parseImagePathToURL(item.user.avatar)
                                  : avatarIcon
                              }
                              alt="avatarIcon"
                            />
                          </a>
                        </div>
                        <div className="text-wrapper">
                          <strong className="dark">
                            <a href="#">
                              {decodeHTML(
                                item?.lastMessage || item.room?.lastMessage
                              )}
                            </a>
                          </strong>
                          <span className="time">
                            {item?.lastMessageTimestamp
                              ? moment(item.lastMessageTimestamp).fromNow()
                              : "Just now"}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="view-all-notifications-btn-wrapper">
              <button className="view-all-notification-btn">
                View all notifications
              </button>
            </div>
          </>
        ) : (
          <>
            <NoNotificationComponent />
          </>
        )}
      </div>
    </div>
  );
}
