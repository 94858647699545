import React from 'react';
import Logo from '../assets/images/icons/logo.svg'
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

export default function LogoTopBar() {
    return (
        <div className='logo-top-bar'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8 align-self-center col-8">
                        <div className="logo-wrapper">
                            <Link to="/">
                                <img src={Logo} alt="Logo" className='logo' />
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-4 align-self-center col-4">
                        <div className="language-drodown-wrapper">
                        <DropdownButton
                                    id={`dropdown-button-drop-down`}
                                    drop={'down'}   
                                    variant="secondary"
                                    className="custom-dropdown"
                                    title={'Eng'}
                                >
                                    <Dropdown.Item eventKey="1" > Eng</Dropdown.Item>
                                    <Dropdown.Item eventKey="2" >Eng</Dropdown.Item>
                                    <Dropdown.Item eventKey="3">Eng </Dropdown.Item>
                                    <Dropdown.Item eventKey="4">Eng </Dropdown.Item>
                                </DropdownButton>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    )
}