import React, { useEffect, useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { LuEye, LuEyeOff } from "react-icons/lu";
import googleIcon from "../assets/images/icons/google-icon.svg";
import circularCrossIcon from "../assets/images/icons/circular-cross-icon.svg";
import { FaArrowLeftLong } from "react-icons/fa6";

import LogoTopBar from "../components/LogoTopBar";
import InvalidEmailPassword from "../components/InvalidEmailPassword";
import LoginAsBrandBottomBar from "../components/LoginAsBrandBottomBar";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  userLoginAsyncThunk,
  userResetPasswordAsyncThunk,
} from "../redux/pagesSlices/authSlice";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[@$!%*?&#]/,
      "Password must contain at least one special character"
    ),
  confirmPassword: Yup.string()
    .required("Password confirmation is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

export default function EnterNewPassword() {
  const [token, setToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tokenValue = queryParams.get("token");
    if (tokenValue) {
      setToken(tokenValue);
    }
  }, []);

  const d = useDispatch();
  const loading = useSelector(
    (s) => s.auth?.loadings?.userResetPasswordAsyncThunk
  );
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    d(
      userResetPasswordAsyncThunk({
        password: values.password,
        token,
        navigate,
      })
    );
  };
  return (
    <div className="forget-password-page">
      <div className="inner-main-page">
        <LogoTopBar />
        <div className="inner-page-content">
          <div className="login-page-content">
            <div className="title-wrapper text-center">
              <h2>Forgot Password?</h2>
              <p className="dark_text">
                {" "}
                Enter your new password and you’re good to go.
              </p>
            </div>

            {/* {showError && <InvalidEmailPassword />} */}

            <Formik
              initialValues={{ password: "", confirmPassword: "" }}
              validationSchema={validationSchema}
              // onSubmit={(values, { setSubmitting }) => {
              //     console.log('Form submitted:', values);
              //     setSubmitting(false); // Stop submit button loading after submission
              // }}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, errors }) => (
                <Form className="form transparent">
                  <div className="field-wrapper">
                    <div className="form-group with-right-icon">
                      <label htmlFor="password">Password</label>
                      <Field
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Password"
                        id="password"
                        name="password"
                      />
                      {showPassword ? (
                        <LuEye
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <LuEyeOff
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                    <span className="error-msg">
                      <ErrorMessage name="password" />
                    </span>
                  </div>
                  <ul className="pass-feature">
                    <li
                      className={values.password.match(/[a-z]/) ? "active" : ""}
                    >
                      <FaRegCheckCircle /> One lowercase character
                    </li>
                    <li className={values.password.length >= 8 ? "active" : ""}>
                      <FaRegCheckCircle /> 8 character minimum
                    </li>
                    <li></li>
                    <li
                      className={
                        values.password.match(/[!@#$%^&*(),.?":{}|<>]/)
                          ? "active"
                          : ""
                      }
                    >
                      <FaRegCheckCircle /> One special character
                    </li>
                    <li
                      className={values.password.match(/[0-9]/) ? "active" : ""}
                    >
                      <FaRegCheckCircle /> One number
                    </li>
                    <li></li>
                    <li
                      className={values.password.match(/[A-Z]/) ? "active" : ""}
                    >
                      <FaRegCheckCircle /> One uppercase character
                    </li>
                  </ul>
                  <div className="field-wrapper">
                    <div className="form-group with-right-icon">
                      <label htmlFor="password">Confirm password</label>
                      <Field
                        type={showConfirmPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Password"
                        id="password"
                        name="confirmPassword"
                      />
                      {showConfirmPassword ? (
                        <LuEye
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <LuEyeOff
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                    <span className="error-msg">
                      <ErrorMessage name="confirmPassword" />
                    </span>
                  </div>
                  <div className="form-footer">
                    {/* <button type="submit" disabled={isSubmitting} className='btn-style full'> Next  </button> */}
                    <button
                      type="submit"
                      disabled={loading}
                      className="btn-style full large_font"
                    >
                      {loading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Reset Password"
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <LoginAsBrandBottomBar />
    </div>
  );
}
