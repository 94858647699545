import React, { useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import leftLongIcon from "../assets/images/icons/left-long-arrow.svg";
import jobImg from "../assets/images/icons/job-image.svg";
import mapIcon from "../assets/images/icons/map-icon.svg";
import appliedStatusIcon from "../assets/images/icons/applied-status-icon.svg";
import videoFileLightICon from "../assets/images/icons/video-file-light-icon.svg";
import videoFileIcon from "../assets/images/icons/video-file-wrapper.svg";
import liveIcon from "../assets/images/icons/live-icon.svg";
import { CgArrowTopRight } from "react-icons/cg";
import { FaCheck } from "react-icons/fa6";
import { PiPencilSimpleBold } from "react-icons/pi";
import EditProposalComponent from "../components/EditProposalComponent";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationAsyncThunk } from "../redux/pagesSlices/applicationSlice";
import { returnArray } from "../utils/utils";
import moment from "moment";
import { parseImagePathToURL } from "../utils/globalFunctions";
import LoaderComponent from "../components/LoaderComponent";
import WithDrawlProposalModal from "../components/WithDrawlProposalModal";
import { Button } from "react-bootstrap";

export default function TrackProposal() {
  const d = useDispatch();
  const { id } = useParams();
  const [activeWithdrawlModal, setActiveWuthDrawlModal] = useState(false);
  const navigate = useNavigate();
  const proposalDetails = useSelector((s) => s.application.application);
  console.log("🚀 ~ TrackProposal ~ proposalDetails:", proposalDetails);
  const loading = useSelector(
    (s) => s.application?.loadings?.getApplicationAsyncThunk
  );

  useEffect(() => {
    if (id) d(getApplicationAsyncThunk(id));
  }, [d, id]);

  const brand = proposalDetails?.brandId;
  const videoPrices = returnArray(proposalDetails?.offeredVideoPrice);
  const livePrices = returnArray(proposalDetails?.offeredLivePrice);
  const job = proposalDetails?.jobId ?? {};
  const numberOfVideos = returnArray(videoPrices).reduce(
    (acc, item) => acc + item.count,
    0
  );
  const numberOfLives = returnArray(livePrices).reduce(
    (acc, item) => acc + item.count,
    0
  );

  const [editModalState, setEditModalState] = useState(false);
  const editModalOpenHandler = () => {
    setEditModalState(true);
  };
  const editModalCloseHandler = () => {
    setEditModalState(false);
  };

  const withDrawlModalShowHandler = () => {
    setActiveWuthDrawlModal(true);
  };
  const withDrawlModalCloseHandler = () => {
    setActiveWuthDrawlModal(false);
  };
  // Steps and status mapping
  const steps = [
    {
      id: 1,
      title: "Apply for job",
      description: "You applied to this job",
    },
    {
      id: 2,
      title: "Profile review",
      description: "Your application will be reviewed at this stage.",
    },
    {
      id: 3,
      title: "Brand note",
      description: "Brand drops a note if they have any",
      extra: (
        <div className="brand-note-wrapper">
          <div className="notification">
            <p className="dark">
              The client has requested that you review your prices so that you
              can be considered for the job.
            </p>
          </div>
          <Link
            to="#"
            className="btn-style text blue"
            onClick={editModalOpenHandler}
          >
            Edit proposal <PiPencilSimpleBold />
          </Link>
          <span className="edited-proposal-sent">
            Proposal edited and sent.
          </span>
        </div>
      ),
    },
    {
      id: 4,
      title: "Get hired/Rejected",
      description: "If all goes well, the client will offer you the job.",
      badges: [
        {
          text: "Hired",
          style: { background: "#E5F1C8", color: "#7FB519" },
        },
        {
          text: "Rejected",
          style: { background: "#E5F1C8", color: "#7FB519" },
        },
      ],
    },
  ];

  const getClassName = (step) => {
    const status = proposalDetails?.status;
    const stepStatusMap = {
      1: "main-step-wrapper complete",
      2: status === "interested" || status === "pass" || status === "approved"
        ? "main-step-wrapper complete"
        : "main-step-wrapper active",
      3: status === "pass" || status === "approved"
        ? "main-step-wrapper complete"
        : status === "interested"
          ? "main-step-wrapper active"
          : "main-step-wrapper",
      4: status === "pass" || status === "approved"
        ? "main-step-wrapper complete"
        : "main-step-wrapper",
    };
    return stepStatusMap[step.id] || "main-step-wrapper";
  };

  const handleCallback = () => {
    // navigate("/dashboard/my-proposals");
    withDrawlModalCloseHandler();
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div className="inner-page-wrapper active m-0">
      <div className="track-proposal-page">
        <div className="upper-section">
          <div className="container large">
            <div className="top-bar">
              <Link to={`/dashboard/proposals/${id}`} className="btn-style text">
                <img src={leftLongIcon} alt="leftLongIcon" /> Back
              </Link>
            </div>
            <div className="proposal-title-wrapper">
              <div className="row">
                <div className="col-md-8 mb-3 mb-md-0">
                  <div className="title-wrapper">
                    <h2>{job?.jobTitle}</h2>
                    <div className="job-info">
                      <div className="main-wrapper">
                        <div className="img-wrapper">
                          <img
                            src={
                              brand?.avatar
                                ? parseImagePathToURL(brand?.avatar)
                                : jobImg
                            }
                            alt="jobImg"
                          />
                        </div>
                        <div className="text-wrapper">
                          <div className="title">
                            <strong className="medium">
                              <a href="#" className="blue">
                                {job?.brandName}
                              </a>
                            </strong>
                            <div className="location">
                              <img src={mapIcon} alt="mapIcon" />
                              <p className="medium dark">{job?.location}</p>
                            </div>
                          </div>
                          <div className="link">
                            <p className="dark medium">
                              <Link target="_blank" href={brand?.website}>
                                {brand?.website}
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="btn-wrapper text-end">
                    <Button
                      onClick={withDrawlModalShowHandler}
                      className="btn-style white-bg large_font"
                    >{
                        proposalDetails?.withdraw ?
                          'Undo withdraw proposal'
                          :
                          'Withdraw proposal'
                      }
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="proposal-detail-main-wrapper">
          <div className="container large">
            <div className="row">
              <div className="col-md-5 mb-4 mb-md-0">
                <div className="main-detail-wrapper">
                  <div className="title">
                    <strong className="large dark">Proposal tracking</strong>
                  </div>
                  {steps.map((step) => (
                    <div key={step.id} className={getClassName(step)}>
                      <div className="number-wrapper">
                        {getClassName(step).includes("complete") ? (
                          <FaCheck />
                        ) : (
                          <strong className="medium">{step.id}</strong>
                        )}
                      </div>
                      <div className="detail-wrapper">
                        <div className="text">
                          <strong className="medium dark">{step.title}</strong>
                          {getClassName(step).includes("complete") &&
                            step?.badges?.length && (
                              <span
                                className="badge"
                                style={
                                  proposalDetails?.status === "approved"
                                    ? step.badges[0].style
                                    : step.badges[1].style
                                }
                              >
                                {proposalDetails?.status === "approved"
                                  ? "Hired"
                                  : "Rejected"}
                              </span>
                            )}
                        </div>
                        <p className="medium">{step.description}</p>
                        {!getClassName(step).includes("complete") && step.extra}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-7">
                <div className="main-detail-wrapper">
                  <div className="title">
                    <strong className="large dark">Project details</strong>
                  </div>
                  <div className="about-job-main-wrapper">
                    <div className="main-upper-wrapper">
                      <div className="text-wrapper">
                        <div className="title-wrapper">
                          <img
                            src={appliedStatusIcon}
                            alt="appliedStatusIcon"
                          />
                          <span>Applied</span>
                        </div>
                        <div className="info">
                          <span className="dark">
                            {moment(job?.createdAt).format("MM/DD/YYYY")}
                          </span>
                        </div>
                      </div>
                      <div className="text-wrapper">
                        <div className="title-wrapper">
                          <img
                            src={videoFileLightICon}
                            alt="videoFileLightICon"
                          />
                          <span>Content Type</span>
                        </div>
                        <div className="info">
                          <ul className="proposal-type-list">
                            {returnArray(job?.types).map((type, index) => (
                              <li key={index} className={`${type == 'video' ? 'video' : 'live'}`}>
                                <img
                                  src={
                                    type === "video" ? videoFileIcon : liveIcon
                                  }
                                  alt="Icon"
                                />
                                {type === "video"
                                  ? numberOfVideos
                                  : numberOfLives}{" "}
                                {type}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="pricings-main-wrapper">
                      <div className="pricing-wrapper">
                        <div className="title-wrapper">
                          <span className="dark">Video Prices</span>
                        </div>
                        <ul className="listings">
                          {videoPrices?.map((price, index) => (
                            <li key={index}>
                                 <span>{price.count} videos:{" "}</span>
                                 <strong className="dark large">${price.price}</strong>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="pricing-wrapper">
                        <div className="title-wrapper">
                          <span className="dark">Amount Live</span>
                        </div>
                        <ul className="listings">
                          {livePrices?.map((price, index) => (
                            <li key={index}>
                              <span> {price.count} Live:{" "}</span>
                              <strong className="dark large">${price.price}</strong>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="description-wrapper">
                      <strong className="dark medium">Job description</strong>
                      <p>
                        {brand?.description?.length > 100
                          ? brand?.description?.slice(0, 100) + "..."
                          : brand?.description}
                      </p>
                      <Link
                        to={`/dashboard/job/${job?.id}`}
                        className="btn-style text blue"
                      >
                        View full details <CgArrowTopRight />
                      </Link>
                    </div>
                    <div className="description-wrapper">
                      <strong className="dark medium">
                        Why are you a good fit?
                      </strong>
                      <p>{proposalDetails?.coverLetter}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <EditProposalComponent
          editModalState={editModalState}
          editModalCloseHandler={editModalCloseHandler}
        />
        {activeWithdrawlModal && (
          <WithDrawlProposalModal
            withDrawlModalCloseHandler={withDrawlModalCloseHandler}
            activeWithdrawlModal={activeWithdrawlModal}
            application={proposalDetails}
            callBack={handleCallback}
          />
        )}
      </div>
    </div>
  );
}
