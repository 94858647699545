import React, { useState } from "react";
import { Link } from "react-router-dom";
import JobsImg from "../assets/images/job-img-1.svg";
import { parseImagePathToURL } from "../utils/globalFunctions";
import smsIcon from "../assets/images/icons/sms-icon.svg";
import { returnArray } from "../utils/utils";
export default function MyJob({ application }) {
  return (

    <tr>
      <td>
        <div className="main-wrapper">
          <div className="img-wrapper">
            <Link to={`/dashboard/track-project/${application?._id}?applicationId=${application?.applicationStatus[0]._id}`}>
              <img
                src={
                  returnArray(application?.image)?.[0]
                    ? parseImagePathToURL(application?.image[0])
                    : JobsImg
                }
                alt="JobsImg"
              />
            </Link>
          </div>
          <div className="text-wrapper">
            <p className="dark">
              <Link to={`/dashboard/track-project/${application?._id}?applicationId=${application?.applicationStatus[0]._id}`}>{application?.jobTitle}</Link>
            </p>
          </div>
        </div>
      </td>
      <td>
        <div className="main-status-wrapper">
          <ul className="status-list">
            <li className={application?.tiktokShopUrl ? "complete" : ""}>
              <span className="status"></span> Tracking URL linked{" "}
            </li>
            <li className="active">
              <span className="status"></span> Message Brand{" "}
            </li>
            <li className={application?.applicationStatus?.[0]?.paymentInfo?.isPaid ? "complete" : ""}>
              <span className="status"></span>Payment Received{" "}
            </li>
            <li className={application?.applicationStatus?.[0]?.sampleStatus === "received" ? "complete" : ""} >
              <span className="status"></span> Samples Received
            </li>
            <li>
              <span className="status"></span> Create Content
            </li>
            <li className={application?.applicationStatus?.[0]?.jobStatus === "completed" ? "complete" : ""}>
              <span className="status"></span> Complete{" "}
            </li>
          </ul>
          <div className="btns-wrapper">
            <Link
              to={`/dashboard/messages?id=${application?.brandId}&jobId=${application?.id || application?._id
                }`}
              className="btn-style light"
            >
              {" "}
              <img src={smsIcon} alt="smsIcon" /> Message Brand
            </Link>
          </div>
        </div>
      </td>
    </tr>
  );
}
