import { Link } from "react-router-dom";
import avatarIcon from "../../assets/images/icons/avatar-icon.svg";
import searchIcon from "../../assets/images/icons/search-icon-wrapper.svg";
import filtrICon from "../../assets/images/icons/bar-filter-icon.svg";
import speakerIcon from "../../assets/images/icons/speaker-icon.svg";
import archivedIcon from "../../assets/images/icons/archive-icon.svg";
import cancelIcon from "../../assets/images/icons/cancel-icon.svg";
import flagIcon from "../../assets/images/icons/flag-icon-outline.svg";
// import { useState } from "react";
import { parseImagePathToURL } from "../../utils/globalFunctions";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { DropdownButton } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import { BsThreeDots } from "react-icons/bs";
import BroadCastModal from "../BroadCastModal";
import { useState } from "react";
import ReportContactModal from "../modals/ReportContactModal";
import NoChatComponent from "./NoChatComponent";


const ChatSidebar = ({
  rooms,
  presenceData,
  selectedRoom,
  user,
  getRoomsOfUser,
  joinRoom,
  users,
  roomLoading,
  handleDeleteRoom,
  handleActiveChatOpen,
  activeChatState,
}) => {
  console.log("🚀 ~ rooms:", rooms)
  console.log("🚀 ~ selectedRoom:", selectedRoom)

  const isUserOnline = (userId) => {
    return presenceData.some((client) => client.clientId === userId);
  };
  //   const [isUlVisible, setUlVisible] = useState(false);
  //   const [searchQuery, setSearchQuery] = useState("");
  //   const [isSearching, setIsSearching] = useState(false);

  //   const handleFocus = async (e) => {
  //     e.stopPropagation();
  //     await getRoomsOfUser();
  //     setUlVisible(!isUlVisible);
  //   };

  //   const filteredUsers = users.filter(
  //     (user) =>
  //       user?.profile?.fullName
  //         ?.toLowerCase()
  //         .includes(searchQuery.toLowerCase()) ||
  //       user?.profile?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  //   );

  function decodeHTML(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  const [boardCastModalState, setBoardCastModalState] = useState(false);
  const [reportContactModalState, setReportContactModalState] = useState(false);

  const broadCastModalOpenHandler = () => {
    setBoardCastModalState(true)
  }
  const broadCastModalCloseHandler = () => {
    setBoardCastModalState(false)
  }
  const reportContactModalOpenHandler = () => {
    setReportContactModalState(true)
  }
  const reportContactModalCloseHandler = () => {
    setReportContactModalState(false)
  }

  return (
    <div className="notifications-sidebar-main-wrapper">
      <div className="sidebar-ttile">
        <h3>Messages</h3>
        <DropdownButton
          id={`dropdown-button-drop-down `}
          drop={'down'}
          variant="secondary"
          className="custom-dropdown three-dots-line"
          title={<BsThreeDots />}
        >
          <Dropdown.Item eventKey="1" onClick={broadCastModalOpenHandler}> <img src={speakerIcon} alt="speakerIcon" /> Action</Dropdown.Item>
          <Dropdown.Item eventKey="2"><img src={archivedIcon} alt="speakerIcon" /> Another action</Dropdown.Item>
          <Dropdown.Item eventKey="3"><img src={cancelIcon} alt="cancelIcon" /> Blocked contacts</Dropdown.Item>
          <Dropdown.Item eventKey="3" onClick={reportContactModalOpenHandler}><img src={flagIcon} alt="flagIcon" /> Report</Dropdown.Item>
        </DropdownButton>
      </div>
      <div className="messages-filter-werapper">
        <div className="search-bar-wrapper">
          <img src={searchIcon} alt="searchIcon" className="search-icon" />
          <input
            type="text"
            className="form-control"
            placeholder="Search messages"
          />
        </div>
        <img src={filtrICon} alt="filtrICon" className="filter-icon" />
      </div>
      <div className="notifications-list-wrapper">
        {roomLoading ? (
          <div>
            {" "}
            <Skeleton count={5} />
          </div>
        ) : Array.isArray(rooms) && rooms.length > 0 ? (
          rooms.map((room, index) => {
            const isGroup = room?.type === "group";
            const isSystem = room?.type === "system";
            const handleClick = async () => {
              joinRoom(
                { ...room?.user, applicationId: room?.applicationId },
                room,
                room?.unreadMessagesCount
              );
            };
            const messageDate = room?.lastMessageTimestamp
              ? new Date(room?.lastMessageTimestamp)
              : new Date();
            // const messageDate = new Date(room?.lastMessageTimestamp)
            const formattedTime = messageDate
              .toLocaleString("en-US", {
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
              })
              .toUpperCase();

            return (
              <div
                className={`message-wrapper ${(selectedRoom?._id || selectedRoom?.id) ===
                    (room?._id || room?.id)
                    ? "active1"
                    : ""
                  }  `}
                key={index}
                onClick={() => {
                  handleClick();
                  handleActiveChatOpen();
                }}
              >
                <div className="img-wrapper position-relative">
                  <Link to="#">
                    <img
                      src={
                        room?.user?.avatar
                          ? parseImagePathToURL(room.user.avatar)
                          : avatarIcon
                      }
                      alt="avatar-img"
                    />
                  </Link>
                  {isSystem ? (
                    ""
                  ) : isUserOnline(
                    room?.user?.user || room?.user?.id || room?.user?._id
                  ) ? (
                    <p
                      className="position-absolute mb-0"
                      style={{
                        right: "0px",
                        bottom: "0px",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        background: "#198754",
                      }}
                    ></p>
                  ) : (
                    <p
                      className="position-absolute mb-0"
                      style={{
                        right: "0px",
                        bottom: "0px",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        background: "#ff3838",
                      }}
                    ></p>
                  )}
                </div>
                <div className="text-wrapper">
                  <div className="sender-name">
                    <strong className="dark medium semi_bold">
                      <Link href="#">
                        {isGroup || isSystem ? room?.name : room?.user?.name}
                      </Link>
                    </strong>
                    <span className="time">{formattedTime}</span>
                  </div>
                  <div className="message-div">
                    <span
                      className="message"
                      dangerouslySetInnerHTML={{
                        __html: decodeHTML(
                          room?.applicationId?.jobId?.jobTitle ||
                          room?.lastMessage ||
                          room?.user?.name ||
                          ""
                        ),
                      }}
                    ></span>
                    {room.unreadMessagesCount > 0 && (
                      <strong className="notifications-num white">
                        {room.unreadMessagesCount}
                      </strong>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <NoChatComponent />
        )}
      </div>
      <BroadCastModal boardCastModalState={boardCastModalState} broadCastModalCloseHandler={broadCastModalCloseHandler} />
      <ReportContactModal reportContactModalState={reportContactModalState} reportContactModalCloseHandler={reportContactModalCloseHandler} />
    </div>
  );
};

export default ChatSidebar;
