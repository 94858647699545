import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import envelopIcon from "../assets/images/icons/envelop-icon.svg";
import notificationIcon from "../assets/images/icons/notification-icon.svg";
import avatarIcon from "../assets/images/icons/avatar-icon.svg";
import Logo from "../assets/images/icons/logo.svg";
import userLightIcon from "../assets/images/icons/user-light-icon.svg";
import tooltipImg from "../assets/images/toolt-tip-img.png";
import settingsLightIcon from "../assets/images/icons/settings-light-icon.svg";
import helpLightIcon from "../assets/images/icons/help-light-icon.svg";
import logoutIcon from "../assets/images/icons/logout-light-icon.svg";
import menuIcon from "../assets/images/icons/menu-icon.png";
import bookFileIcon from "../assets/images/icons/book-file-icon'.svg";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { useDispatch, useSelector } from "react-redux";
import { TbLogout } from "react-icons/tb";
import { toggleSidebarMobile, userLogoutAsyncThunk } from "../redux/pagesSlices/authSlice";
import { Button } from "react-bootstrap";
import NotificationComponent from "./NotificationsComponent";
import {
  fetchRoomsAsyncThunk,
  setMessages,
  setRooms,
} from "../redux/pagesSlices/chatSlice";
import { useChannel, usePresence } from "ably/react";
import { toast } from "react-toastify";
import HeaderTooltipComponent from "./HeaderTooltipComponent";
import { returnArray } from "../utils/utils";
import { IoClose } from "react-icons/io5";
import { FaArrowCircleRight } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { useLocation } from "react-router-dom";



export default function DashboardHeader() {
  const { profile, user } = useSelector((s) => s.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(userLogoutAsyncThunk());
  };
  const [hasNotification, setHasNotification] = useState(true);
  const [showNotification, setShowNotifications] = useState(false);
  const [showHeaderMenuState, setShowHeaderMenuState] = useState(false);
  const notificationRef = useRef(null);

  const showNotificationsHandler = () => {
    setShowNotifications(true);
  };
  const hideNotificationsHandler = () => {
    setShowNotifications(false);
  };
  const rooms = useSelector((s) => s.chat.rooms);
  const messages = useSelector((s) => s.chat.messages?.results);
  const notifications = useSelector((s) => s.chat.notifications);

  const { updateStatus } = usePresence(
    { channelName: "notifications" },
    { isOnline: true }
  );
  const handleClickOutside = (event) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      setShowNotifications(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    dispatch(fetchRoomsAsyncThunk());
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [toastId, setToastId] = useState(null);
  const { channel: notification } = useChannel("notifications", (message) => {
    console.log("🚀 ~ useChannel ~ message:", message);
    if (message.name === "notification") {
      const roomId = message?.data?.room_id;
      const content = message?.data?.content;
      const created_at = message?.data?.createdAt;
      if (message?.data?.sender_id === (user?.id || user._id)) return;
      if (message?.data?.recipient_id !== (user?.id || user._id)) return;
      if (messages[messages.length - 1]?.id === message?.data?.id) return;
      if (toastId) {
        toast.dismiss(toastId);
      }
      setToastId(
        toast.info("You have new unread messages!", {
          position: "top-right",
          autoClose: 10000,
          closeOnClick: true,
          closeButton: true,
        })
      );
      //   playNotificationSound();
      dispatch(
        setRooms(
          rooms
            .map((room) => {
              if ((room.id || room._id) === roomId) {
                return {
                  ...room,
                  unreadMessagesCount: room.unreadMessagesCount + 1,
                  lastMessage: content,
                  lastMessageTimestamp: created_at,
                };
              }
              return room;
            })
            .sort((a, b) => {
              return (
                new Date(b.lastMessageTimestamp).getTime() -
                new Date(a.lastMessageTimestamp).getTime()
              );
            })
        )
      );
    }
    if (message.name === "delete_room") {
      const roomId = message?.data?.room_id;
      dispatch(setRooms(rooms.filter((room) => room.id !== roomId)));
    }
    if (message.name === "new room") {
      dispatch(fetchRoomsAsyncThunk());
    }
    if (message.name === "delete_message") {
      dispatch(
        setMessages(
          messages.filter(
            (msg) => (msg.id || msg._id) !== message?.data?.messageId
          )
        )
      );
      //   if(message?.data?.isLastMessage){
      //     dispatch(setRooms(rooms.map((room) => {
      //       if (room.name === channelName) {
      //         return {
      //           ...room,
      //           lastMessage: message?.data?.secondLastMessage?.content,
      //           lastMessageTimestamp: message?.data?.secondLastMessage?.createdAt,
      //         };
      //       }
      //       return room;
      //     })))
      //   }
    }
  });

  const showSidebarOpenBtn = ["/dashboard/find-jobs", "/dashboard/my-proposals"];

  const showHeaderMenu = () => {
    setShowHeaderMenuState(!showHeaderMenuState)
  }
  const closeHeaderMenu = () => {
    setShowHeaderMenuState(false)
  }

  return (
    <header className="dashboard-header">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-8 col-lg-8 col-md-4 col-4 align-self-center">
            <div className="logo-wrapper">
              {showSidebarOpenBtn.includes(location.pathname) && (
                <button className="transparent sidebar-menu-icon d-flex d-lg-none" onClick={() => dispatch(toggleSidebarMobile(true))}>
                  <FaArrowRight />
                </button>
              )}

              <Link to="/dashboard/home">
                <img src={Logo} alt="Logo" className="logo" />
              </Link>
            </div>
            <div className={`header-menu ${showHeaderMenuState ? 'active' : ''}`}>
              {/* <button className="cross-btn" onClick={closeHeaderMenu}><IoClose/></button> */}
              <ul className="menu">
                <li>
                  <NavLink exact to="/dashboard/home" activeClassName="active" onClick={() => setShowHeaderMenuState(false)}>
                    Dashboard
                  </NavLink>{" "}
                </li>
                <li>
                  <NavLink to="/dashboard/find-jobs" className={"find-jobs-tooltip"} activeClassName="active" onClick={() => setShowHeaderMenuState(false)}>
                    Find Jobs
                  </NavLink>
                  {/* <HeaderTooltipComponent /> */}
                </li>
                <li>
                  <NavLink
                    to="/dashboard/my-proposals"
                    className="my-proposals-tooltip"
                    activeClassName="active"
                    onClick={() => setShowHeaderMenuState(false)}
                  >
                    Proposals
                  </NavLink>
                  {/* <HeaderTooltipComponent /> */}
                </li>
                <li>
                  <NavLink to="/dashboard/my-jobs" className="my-jobs-tooltip" activeClassName="active" onClick={() => setShowHeaderMenuState(false)}>
                    My Jobs
                  </NavLink>
                  {/* <HeaderTooltipComponent /> */}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-8 col-8 align-self-center">
            <div className="right-menu">
              <ul className="menu">
                <li className={`hamburger-icon-li`}>
                  <a href="#" onClick={showHeaderMenu}>
                    {showHeaderMenuState ?
                      <IoClose />
                      :
                      <img src={menuIcon} alt="menuIcon" />
                    }

                  </a>
                </li>
                <li>
                  <a href="#">
                      <img src={bookFileIcon} alt="bookFileIcon" />

                  </a>
                </li>
                <li
                  className={
                    notifications?.reduce(
                      (sum, message) => sum + message.unreadMessagesCount,
                      0
                    ) || 0
                      ? "has-notification"
                      : ""
                  }
                >
                  <NavLink to="/dashboard/messages" activeClassName="active">
                    <img src={envelopIcon} alt="envelopIcon" />
                  </NavLink>
                </li>
                
                <li
                  className={
                    returnArray(notifications)?.length > 0
                      ? "has-notification"
                      : ""
                  }
                >
                  <Link
                    to="#"
                    className={`${showNotification ? "active" : ""}`}
                    onClick={showNotificationsHandler}
                  >
                    <img src={notificationIcon} alt="notificationIcon" />
                  </Link>
                </li>
              </ul>
              <div className="avatar-dropdown-wrapper profile-tooltip">

                {/* <img src={parseImagePathToURL(profile?.avatar || avatarIcon)} alt="avatarIcon" className="avatar-img" /> */}
                <DropdownButton
                  id={`dropdown-button-drop-down`}
                  drop={"down"}
                  variant="secondary"
                  className="custom-dropdown"
                  title={<>
                    {profile?.avatar ? (
                      <img
                        src={parseImagePathToURL(profile?.avatar || avatarIcon)}
                        alt="avatarIcon"
                        className="avatar-img"
                      />
                    ) : (
                      <img
                        src={avatarIcon}
                        alt="avatarIcon"
                        className="avatar-img"
                      />
                    )}
                    <span>
                      {user.userName}</span>
                  </>}
                >
                  <Dropdown.Item
                    eventKey="1"
                    as={Link}
                    to="/dashboard/my-profile"
                  >
                    <img src={userLightIcon} alt="userLightIcon" /> View profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="2"
                    as={Link}
                    to="/dashboard/settings"
                  >
                    <img src={settingsLightIcon} alt="userLightIcon" /> Settings
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="3">
                    <img src={helpLightIcon} alt="userLightIcon" /> Help &
                    Support
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout} eventKey="4">
                    <img src={logoutIcon} alt="userLightIcon" /> Log out
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationComponent
        hasNotification={hasNotification}
        showNotification={showNotification}
        hideNotificationsHandler={hideNotificationsHandler}
        notificationRef={notificationRef}
        notifications={notifications}
      />
    </header>
  );
}
