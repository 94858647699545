import React from "react";

import noJobsImg from '../assets/images/icons/no-jobs-img.svg'
import { Link } from "react-router-dom";

export default function NoJobsComponent({image,title,description,btnText,route}) {
    return (
        <div className="no-jobs-main-wrapper">
            <div className="main-wrapper">
                <div className="img-wrapper">
                    <img src={image} alt="noJobsImg" className="no-jobs-img" />
                </div>
                <div className="content-wrapper">
                    <strong className="dark large">{title}</strong>
                    <p>{description}</p>
                    <div className="btn-wrapper">
                        <Link to={route} className="btn-style ">{btnText}</Link>
                        <Link to="#" className="btn-style transparent light">How it Works</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}