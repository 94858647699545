import { useState, useEffect } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import { useDispatch } from "react-redux";
import { accountOnboardingAsyncThunk } from "../redux/pagesSlices/paymentSlice";

export const useStripeConnect = (connectedAccountId) => {
  const dispatch = useDispatch();
  const [stripeConnectInstance, setStripeConnectInstance] = useState();

  useEffect(() => {
    if (connectedAccountId) {
      const fetchClientSecret = async () => {
        const clientSecret = await dispatch(
          accountOnboardingAsyncThunk({ id: connectedAccountId })
        ).unwrap();

        return clientSecret;
      };

      setStripeConnectInstance(
        loadConnectAndInitialize({
          publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
          fetchClientSecret,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: "#635BFF",
            },
          },
        })
      );
    }
  }, [connectedAccountId]);

  return stripeConnectInstance;
};

export default useStripeConnect;
