import React, { useEffect } from "react";
import { accountOnboardingAsyncThunk } from "../redux/pagesSlices/paymentSlice";
import { useDispatch } from "react-redux";

const ReauthPage = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const accountId = params.get("accountId");

    const retryOnboarding = async () => {
      const redirectUrl = await dispatch(
        accountOnboardingAsyncThunk({ id: accountId })
      ).unwrap();
      window.location.href = redirectUrl;
    };

    retryOnboarding();
  }, []);

  return <div>Redirecting to onboarding...</div>;
};

export default ReauthPage;
