import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import Logo from "../assets/images/icons/logo.svg";

import GoogleIcon from "../assets/images/icons/google-icon.svg";
import appleIcon from "../assets/images/icons/apple-icon.svg";
import { LuEyeOff } from "react-icons/lu";

import { FaRegCheckCircle } from "react-icons/fa";
import { userRegisterAsyncThunk } from "../redux/pagesSlices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";
import { IoEyeOutline } from "react-icons/io5";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const validationSchema = Yup.object({
  firstName: Yup.string()
    .required("First name is required")
    .min(3, "Name must be at least 3 characters"),
  lastName: Yup.string()
    .required("Last name is required")
    .min(3, "Name must be at least 3 characters"),
  role: Yup.string().required("Role is required").default("creator"),
  userName: Yup.string()
    .matches(
      /^[a-zA-Z0-9_]*$/,
      "Username must contain only letters, numbers, or underscores"
    )
    .required("Username is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Must contain at least one lowercase character")
    .matches(/[A-Z]/, "Must contain at least one uppercase character")
    .matches(/[0-9]/, "Must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Must contain at least one special character"
    ),
});
export default function CreateAccountStep() {
  const router = useNavigate();
  const d = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const loading = useSelector((s) => s.auth?.loadings?.userRegisterAsyncThunk);
  const handleSubmit = async (values, { resetForm }) => {
    console.log(values);
    // const confirmPassword = values.confirmPassword
    // delete values.confirmPassword;
    //   const response = await ApiRequests.register({ ...values, role });
    await d(userRegisterAsyncThunk({ data: values, router }));
    // resetForm();
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="create-account-step-wrapper">
      <div className="logo-wrapper text-center">
        <Link to="/">
          <img src={Logo} alt="Logo" className="logo" />
        </Link>
      </div>
      <div className="text-wrapper text-center">
        <h2>Create Your Creator Account</h2>
        <p className="medium-bold medium">
          You're just a few steps away from collaborating with top brands! Let’s
          get started by setting up your account.
        </p>
      </div>
      <div className="sign-up-choice-wrapper">
        <Link to="#" className="btn-style google-login-btn large full">
          <img src={GoogleIcon} alt="GoogleIcon" /> Sign up with Google
        </Link>
      </div>
      <div className="or-divider">
        <strong className="dark small">OR</strong>
      </div>
      <div className="form-wrapper">
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            userName: "",
            role: "creator",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          // onSubmit={(values, { setSubmitting }) => {
          //     console.log('Form submitted:', values);
          //     setSubmitting(false); // Stop submit button loading after submission
          // }}
        >
          {({ isSubmitting, values }) => (
            <Form className="form">
              <div className="row">
                <div className="col-md-6">
                  <div className="field-wrapper">
                    <div className="form-group">
                      <label htmlFor="f-name">First name</label>
                      <Field
                        type="text"
                        className="form-control"
                        placeholder="Enter your first name"
                        id="f-name"
                        name="firstName"
                      />
                    </div>
                    <span className="error-msg">
                      <ErrorMessage name="firstName" />
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-wrapper">
                    <div className="form-group">
                      <label htmlFor="l-name">Last name</label>
                      <Field
                        type="text"
                        className="form-control"
                        placeholder="Enter your last name"
                        id="l-name"
                        name="lastName"
                      />
                    </div>
                    <span className="error-msg">
                      <ErrorMessage name="lastName" />
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="field-wrapper">
                    <div className="form-group">
                      <label htmlFor="userName">Username</label>
                      <Field
                        type="text"
                        className="form-control"
                        placeholder="Enter your first name"
                        id="userName"
                        name="userName"
                      />
                    </div>
                    <span className="error-msg">
                      <ErrorMessage name="userName" />
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="field-wrapper">
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <Field
                        type="email"
                        className="form-control"
                        placeholder="Enter your email address"
                        id="email"
                        name="email"
                      />
                    </div>
                    <span className="error-msg">
                      <ErrorMessage name="email" />
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="field-wrapper">
                    <div className="form-group with-right-icon">
                      <label htmlFor="password">Password</label>
                      <Field
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Create a password (8 or more characters)"
                        id="password"
                        name="password"
                      />
                      {showPassword ? (
                        <IoEyeOutline onClick={handleShowPassword} style={{cursor : 'pointer'}} />
                      ) : (
                        <LuEyeOff onClick={handleShowPassword} style={{cursor : 'pointer'}} />
                      )}
                    </div>
                    <span className="error-msg">
                      <ErrorMessage name="password" />
                    </span>
                  </div>
                </div>
              </div>
              <ul className="pass-feature">
                <li className={values.password.match(/[a-z]/) ? "active" : ""}>
                  {values.password.match(/[a-z]/) ? (
                    <FaRegCheckCircle size="18px" />
                  ) : (
                    <IoIosCheckmarkCircleOutline color="gray" size="18px" />
                  )}
                  One lowercase character
                </li>
                <li className={values.password.length >= 8 ? "active" : ""}>
                  {values.password.length >= 8 ? (
                    <FaRegCheckCircle size="18px" />
                  ) : (
                    <IoIosCheckmarkCircleOutline color="gray" size="18px" />
                  )}
                  8 character minimum
                </li>
                <li></li>
                <li
                  className={
                    values.password.match(/[!@#$%^&*(),.?":{}|<>]/)
                      ? "active"
                      : ""
                  }
                >
                  {values.password.match(/[!@#$%^&*(),.?":{}|<>]/) ? (
                    <FaRegCheckCircle size="18px" />
                  ) : (
                    <IoIosCheckmarkCircleOutline color="gray" size="18px" />
                  )}
                  One special character
                </li>
                <li className={values.password.match(/[0-9]/) ? "active" : ""}>
                  {values.password.match(/[0-9]/) ? (
                    <FaRegCheckCircle size="18px" />
                  ) : (
                    <IoIosCheckmarkCircleOutline color="gray" size="18px" />
                  )}
                  One number
                </li>
                <li></li>
                <li className={values.password.match(/[A-Z]/) ? "active" : ""}>
                  {values.password.match(/[A-Z]/) ? (
                    <FaRegCheckCircle size="18px" />
                  ) : (
                    <IoIosCheckmarkCircleOutline color="gray" size="18px" />
                  )}
                  One uppercase character
                </li>
              </ul>
              <div className="form-footer">
                {/* <button type="submit" disabled={isSubmitting} className='btn-style full'> Next  </button> */}
                <Button
                  disabled={loading}
                  type="submit"
                  className="btn-style full"
                >
                  {loading ? <Spinner size="sm" /> : "Next"}
                </Button>
                <p className="have-account medium">
                  Already have an account?{" "}
                  <Link to="/sign-in" className="pink bold underline">
                    {" "}
                    Log in
                  </Link>
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
