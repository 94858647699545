import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { MdOutlineAttachment } from "react-icons/md";
import LinkTrackingUrlModal from "./LinkTrackingUrlModal";
import { FaEnvelope } from "react-icons/fa6";
import confirmPayment from "../assets/images/icons/subtract-icon.svg";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import videofile from "../assets/images/icons/video-file-wrapper.svg";
import liveIcon from "../assets/images/icons/live-icon.svg";
import { TbRefresh } from "react-icons/tb";
import { HiMiniInformationCircle } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  receiveApplicationPaymentAsyncThunk,
  receiveApplicationSampleAsyncThunk,
  requestApplicationSampleAsyncThunk,
} from "../redux/pagesSlices/applicationSlice";
import { Spinner } from "react-bootstrap";

export default function TrackJobsStepsContent({ proposalDetails }) {
  const [editModalState, setEditModalState] = useState(false);

  const isLoading = useSelector(
    (s) => s.application?.loadings?.receiveApplicationPaymentAsyncThunk
  );

  const sampleLoading = useSelector(
    (s)=>s.application?.loadings?.receiveApplicationSampleAsyncThunk
  )
  console.log("Is loading data", isLoading);

  const editModalOpenHandler = () => {
    setEditModalState(true);
  };
  const editModalCloseHandler = () => {
    setEditModalState(false);
  };

  console.log("Proposal Details", proposalDetails);

  const [sampleReceive, setSampleReceive] = useState(true);
  console.log("Sample Receive", sampleReceive);

  const dispatch = useDispatch();

  const handleRequestSample = async () => {
    await dispatch(
      receiveApplicationSampleAsyncThunk({
        id: proposalDetails?.id,
        data: {
          sampleStatus: "received",
        },
      })
    );
  };

  const handleSetPaymentStatus = async () => {
    await dispatch(
      receiveApplicationPaymentAsyncThunk({
        id: proposalDetails?.id,
        // data : {
        //   paymentInfo:{
        //     ...paymentInfo,
        //     hasSent: true
        //   }
        // }
      })
    );
  };

  const accordionData = [
    {
      eventKey: "0",
      title: "Tracking URL Linked",
      description:
        "Provide the tracking URL for the content you are creating for the brand. All created content must be trackable for payment and accreditation.",
      action: (
        <button className="custon-btn light" onClick={editModalOpenHandler}>
          <MdOutlineAttachment /> Link Tracking URL
        </button>
      ),
    },
    {
      eventKey: "1",
      title: "Preliminary Discussions",
      description:
        "Discuss the project’s expectations, payment, and deadlines with the brand.",
      action: (
        <Link
          to={`/dashboard/messages?id=${proposalDetails?.brandId?.id}&jobId=${
            proposalDetails?.id || proposalDetails?._id
          }`}
          className="attatch-link-wrapper"
        >
          <button className="custon-btn light">
            <FaEnvelope /> Message {proposalDetails?.brandId?.name}
          </button>
        </Link>
      ),
    },
    {
      eventKey: "2",
      title: "Payment Received",
      description:
        "Confirm the payment has been transferred to you by the brand.",
      action: (
        <button className="custon-btn light" onClick={handleSetPaymentStatus}>
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <>
              <img src={confirmPayment} alt="confirmPayment" /> Confirm Payment
            </>
          )}
        </button>
      ),
    },
    {
      eventKey: "3",
      title: "Samples Received",
      description:
        "Confirm the brand’s products or samples have been received.",
      action: (
        <button className="custon-btn light" onClick={handleRequestSample}>
          {sampleLoading ? (
            <Spinner size="sm" />
          ) : (
            <>
              <IoIosCheckmarkCircle /> Confirm Receipt of Samples
            </>
          )}
        </button>
      ),
    },
    {
      eventKey: "4",
      title: "Content Creation In Progress",
      description: "Work on the video or live sessions as agreed.",
      action: (
        <div className="states-main-wrapper">
          <div className="stats">
            <div className="text blue">
              <CircularProgressbar value={20} strokeWidth={16} />
              <span className="white"> 2/5</span>
            </div>
            <div className="bottom">
              <img src={videofile} alt="videofile" />
              <span className="ex_small">Videos Created</span>
            </div>
          </div>
          <div className="stats">
            <div className="text pink">
              <CircularProgressbar value={20} strokeWidth={16} />
              <span className="white"> 2/5</span>
            </div>
            <div className="bottom">
              <img src={liveIcon} alt="liveIcon" />
              <span className="ex_small">Livestreams</span>
            </div>
          </div>
        </div>
      ),
    },
    {
      eventKey: "5",
      title: "Brand Review & Completion",
      description:
        "Once all content is submitted, the brand will review and confirm the project’s completion.",
      action: (
        <>
          <div className="progress-wrapper-main mt-5">
            <div className="progress-wrapper">
              <div className="circular-progress-wrapper">
                <CircularProgressbar
                  className="video-created-progress-bar blue"
                  value={20}
                  strokeWidth={13}
                />
                <strong className="total medium">5/5</strong>
              </div>
              <div className="text-wrapper">
                <h3>Videos Created</h3>
                <ul className="list-wrapper">
                  <li>
                    <span className="label gray"></span> Expected Videos
                  </li>
                  <li>
                    <span className="label blue"></span> Videos Created
                  </li>
                </ul>
              </div>
            </div>
            <div className="progress-wrapper">
              <div className="circular-progress-wrapper">
                <CircularProgressbar
                  className="video-created-progress-bar pink"
                  value={20}
                  strokeWidth={13}
                />
                <strong className="total medium">5/5</strong>
              </div>
              <div className="text-wrapper">
                <h3>Videos Created</h3>
                <ul className="list-wrapper">
                  <li>
                    <span className="label gray"></span> Expected Videos
                  </li>
                  <li>
                    <span className="label pink"></span> Videos Created
                  </li>
                </ul>
              </div>
            </div>
            <div className="progress-wrapper">
              <div className="circular-progress-wrapper">
                <CircularProgressbar
                  className="video-created-progress-bar gradient"
                  value={20}
                  strokeWidth={13}
                />
                <strong className="total medium">$87</strong>
              </div>
              <div className="text-wrapper">
                <h3>Videos Created</h3>
                <ul className="list-wrapper">
                  <li>
                    <span className="label gray"></span> Expected Videos
                  </li>
                  <li>
                    <span className="label gradient"></span> Videos Created
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="notification-wrapper">
            <HiMiniInformationCircle />
            <strong>
              Burger King was impressed with your performance and would like to
              keep working with you
            </strong>
          </div>
          <div className="attatch-link-wrapper">
            <button className="custon-btn light">
              <TbRefresh /> Accept renewal
            </button>
          </div>
        </>
      ),
    },
  ];

  console.log("Proposal Details", proposalDetails);

  console.log("Status", proposalDetails?.status);

  const getClassName = (accordion) => {
    const status = proposalDetails?.status;
    const stepStatusMap = {
      0:
        status === "applied" ||
        status === "interested" ||
        status === "pass" ||
        status === "approved" ||
        status === "ended"
          ? "accordion-item complete"
          : "accordion-item active",
      1:
        status === "applied" ||
        status === "interested" ||
        status === "pass" ||
        status === "approved" ||
        status === "ended"
          ? "accordion-item complete"
          : "accordion-item active",
      2:
        status === "approved" || status === "pass" || status === "ended"
          ? "accordion-item complete"
          : status === "interested"
          ? "accordion-item active"
          : "accordion-item active",
      3:
        status === "ended" || status === "pass" || status === "approved"
          ? "accordion-item complete"
          : "accordion-item",
      4:
        status === "ended"
          ? "accordion-item complete"
          : status === "pass"
          ? "accordion-item complete"
          : "accordion-item active",
      5:
        status === "ended"
          ? "accordion-item complete"
          : status === "pass" ||
            status === "approved" ||
            status === "interested"
          ? "accordion-item "
          : "accordion-item active",
    };
    return stepStatusMap[accordion.eventKey] || "accordion-item";
  };

  return (
    <div className="track-job-steps-content-wrapper">
      <Accordion
        defaultActiveKey={
          accordionData.find((step) => getClassName(step).includes("active"))
            ?.eventKey
        }
      >
        {accordionData.map((item, index) => (
          <Accordion.Item
            key={item.eventKey}
            eventKey={item.eventKey}
            className={getClassName(item)}
          >
            <Accordion.Header>
              <div className="step-wrapper">
                <div className="count-wrapper">
                  {getClassName(item).includes("complete") ? (
                    <FaCheck />
                  ) : (
                    <span>{+item.eventKey + 1}</span>
                  )}
                </div>
                <div className="contetn-wrapper">
                  <strong className="medium">{item.title}</strong>
                  <p>{item.description}</p>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="attatch-link-wrapper">{item.action}</div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>

      <LinkTrackingUrlModal
        editModalState={editModalState}
        editModalCloseHandler={editModalCloseHandler}
        proposalDetails={proposalDetails}
      />
    </div>
  );
}
