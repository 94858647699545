import React from "react";

import jobImg from "../assets/images/job-img.svg";
import brandImg from "../assets/images/brand-img.svg";
import videoFileIcon from "../assets/images/icons/video-file-wrapper.svg";
import liveIcon from "../assets/images/icons/live-icon.svg";
import applyNowRightArrow from "../assets/images/icons/apply-now-right-arrow.svg";
import { returnArray } from "../utils/utils";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { dateFormat } from "../utils/format";
import { Link, useNavigate } from "react-router-dom";

export default function JobCardComponentListView({
  job,
  jobTitle,
  // brandImg,
  brandName,
  jobPostedTime,
}) {
  const router = useNavigate();

  return (
    <div
      className="col-md-12 mb-3"
      onClick={() => {
        router(`/dashboard/job/${job._id ?? job.id}`);
      }}
    >
      <div className="job-card-main-wrapper list-view-wrapper">
        <div className="row">
          <div className="col-md-7 col-auto">
            <div className="job-title-img-wrapper">
              <div className="job-img-wrapper">
                <Link to={`/dashboard/job/${job._id ?? job.id}`}>
                  <img
                    src={
                      returnArray(job?.image).length > 0
                        ? parseImagePathToURL(job?.image[0])
                        : jobImg
                    }
                    alt="jobImg"
                    className="job-img"
                  />
                </Link>
              </div>
              <div className="job-content-wrapper">
                <div className="brand-top-info">
                  <div className="brand-info">
                    <a href="#">
                      {job?.brandInfo?.avatar ? (
                        <img
                          src={parseImagePathToURL(job?.brandInfo?.avatar)}
                          alt="brandImg"
                        />
                      ) : (
                        <img src={brandImg} alt="brandImg" />
                      )}  
                    </a>
                    <p className="medium-bold dark">
                      <a href="#">{job.brandName || brandName}</a>
                    </p>
                  </div>
                </div>
                <div className="job-title-wrapper">
                  <strong className="dark">
                    <Link to={`/dashboard/job/${job._id ?? job.id}`}>
                      {job.jobTitle || jobTitle}
                    </Link>
                  </strong>
                  <span className="small">
                    {dateFormat(job?.createdAt) || jobPostedTime}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-auto">
            <div className="job-info-wrapper job-content-wrapper">
              <div className="categories-wrapper">
                <span className="small dark d-block medium-bold">
                  Categories
                </span>
                <ul className="categories-list">
                  {returnArray(job?.categories).map((category, index) => (
                    <li key={index}>{category}</li>
                  ))}
                </ul>
              </div>
              <div className="content-age-info-wrapper">
                <div className="info-wrapper brand-top-info">
                  <span className="dark d-block medium-bold small">
                    Content type
                  </span>
                  <div className="platforms-wrapper">
                    {returnArray(job?.types).map((type, index) => (
                      <div
                        key={index}
                        className={`platform ${
                          type == "live" ? "light-pink-bg" : "light-gray-bg"
                        }`}
                      >
                        <img
                          src={type === "live" ? liveIcon : videoFileIcon}
                          alt="videoFileIcon"
                        />
                        <span>{type}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="info-wrapper">
                  <span className="dark d-block medium-bold small">
                    Preferred ages
                  </span>
                  {returnArray(job?.ageRange).map((age, index) => (
                    <span className="preferred-age" key={index}>
                      {" "}
                      {age}{" "}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
